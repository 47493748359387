<template>
  <div>
    <div
      class="card p-1 m-1 is-clickable is-rounded"
      :class="isHovered ? 'has-background-light has-text-dark' : null"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false"
      @click="navigateToArticle(article.id)"
    >
      <header class="card-header has-text-info has-background-info">
        <div class="card-header-title">
          <h4>
            <font-awesome-icon :icon="articleIcon" />
            {{ publicationDate }}
          </h4>
          <span v-markdown="article.title" class="m-2" />
        </div>
      </header>

      <div class="card-content">
        <div>
          <span
            v-for="(authorName, index) of article.authors"
            :key="index"
            class="is-italic has-text-weight-bold"
          >
            {{ authorName }}
          </span>
        </div>

        <div v-markdown="article.abstract" class="content" />
        <div>
          <article-tag
            v-for="(tag, index) of article.tags"
            :key="index"
            :tag="tag"
          />
        </div>
      </div>

      <div class="card-footer">
        <div class="card-footer-item level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <font-awesome-icon icon="heart" class="mx-2" />
              {{ article.upvotes || 0 }}
            </div>
            <div class="level-item">
              <font-awesome-icon icon="heart-broken" class="mx-2" />
              {{ article.downvotes || 0 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleTag from "../common/ArticleTag.vue";
import { mapState } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "ArticleSummary",
  props: ["article"],
  components: {
    ArticleTag,
  },
  data: function () {
    return {
      isHovered: false,
    };
  },
  computed: {
    ...mapState("userModule", ["user"]),
    isLoggedIn: function () {
      return this.user?.isLoggedIn ?? false;
    },
    publicationDate: function () {
      const timestamp = this.article?.publicationTimeStamp;

      return timestamp ? DateTime.fromISO(timestamp)?.toISODate() : null;
    },
    articleIcon: function () {
      if (this.article.category === "ARTICLE") return "newspaper";
      if (this.article.category === "REVIEW") return "gavel";
      return "rss";
    },
  },
  methods: {
    navigateToArticle: function (articleId) {
      this.$router.push({ path: `/articles/${articleId}` });
    },
  },
};
</script>

<style scoped></style>
