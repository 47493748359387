<template>
  <div class="w3-panel w3-padding w3-red w3-border-black w3-margin">
    {{ error }}
  </div>
</template>

<script>
export default {
  name: "ErrorDisplay",
  props: ["error"],
};
</script>
