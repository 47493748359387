<template>
  <div class="modal" :class="{ 'is-active': isOpen }">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-content has-background-light p-3">
      <div>
        <div class="level is-mobile has-background-dark has-text-light p-3">
          <div class="level-left">
            <div class="level-item">
              {{ title }}
            </div>
          </div>
          <div class="level-right">
            <div
              @click.stop="closeModal"
              class="level-item button is-danger m-1 is-small"
            >
              <font-awesome-icon icon="window-close" />
            </div>
          </div>
        </div>

        <div class="has-text-black has-background-light">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GenericModal",
  props: ["title"],
  data: function () {
    return {
      isOpen: false,
    };
  },
  methods: {
    openModal: function () {
      this.isOpen = true;
    },
    closeModal: function (event) {
      event?.preventDefault();
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.generic-modal-header {
  border-bottom: 1px solid black;
}

.close-button {
  cursor: pointer;
}
</style>
