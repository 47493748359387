<template>
  <div class="container is-whitespace-normal" :id="articleViewId">
    <header class="card is-rounded">
      <div class="card-header is-flex-direction-column p-2 m-1">
        <article-title-display
          :article="article"
          class="card-header-title is-centered content"
        />
        <div class="columns">
          <div
            class="column is-italic has-text-weight-bold"
            v-for="(author, index) of article.authors"
            :key="index"
          >
            <a :href="authorUrl(author.id)">
              {{ author.name }}
            </a>
          </div>
          <div v-if="publicationDate" class="column is-italic">
            Publication date: &nbsp; {{ publicationDate }}
          </div>
        </div>
      </div>

      <div class="card-content">
        <div class="m-1">
          <a
            rel="license"
            target="_blank"
            href="http://creativecommons.org/licenses/by-sa/4.0/"
          >
            <img
              alt="Creative Commons License"
              style="border-width: 0"
              src="https://i.creativecommons.org/l/by-sa/4.0/88x31.png"
            />
          </a>
          This work is licensed under a
          <a
            rel="license"
            target="_blank"
            href="http://creativecommons.org/licenses/by-sa/4.0/"
          >
            Creative Commons Attribution-ShareAlike 4.0 International License.
          </a>
        </div>
        <div class="m-1">
          <article-tag v-for="(tag, index) of tags" :key="index" :tag="tag" />
        </div>
      </div>

      <div class="card-footer">
        <voting-widget
          v-if="!editorMode"
          :article="article"
          :authors="article.authors"
          class="card-footer-item"
        />
      </div>
    </header>

    <div class="content p-2">
      <div class="block">
        <h5><strong>Abstract:</strong></h5>
        <div
          v-markdown="{
            markdown: article.abstract,
            references: article.references,
          }"
        />
      </div>

      <div class="block">
        <h5><strong>Article content:</strong></h5>
        <div
          v-markdown="{
            markdown: article.content,
            references: article.references,
          }"
        />
      </div>

      <div
        v-if="article.references && article.references.length > 0"
        class="block"
      >
        <h5>References</h5>
        <reference-display
          v-for="(reference, index) of references"
          :reference="reference"
          :showCiteButton="!!editorMode"
          :key="index"
        />
      </div>

      <div class="block" v-if="!editorMode">
        <h5>Comments</h5>
        <comment-list ref="commentList" :parentId="article.id" />
        <button
          v-if="!isLoggedIn"
          class="button is-info is-outlined"
          :disabled="true"
          @click.prevent="openCommentReplyBox"
          v-tooltip="`You must be logged in to comment`"
        >
          <font-awesome-icon :icon="`comment`" /> &nbsp; Comment
        </button>
        <button
          v-else
          class="button is-info is-outlined mt-2"
          @click.prevent="openCommentReplyBox"
        >
          <font-awesome-icon :icon="`comment`" /> &nbsp; Comment
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import ArticleTag from "../../common/ArticleTag.vue";
import ReferenceDisplay from "../../common/ReferenceDisplay.vue";
import CommentList from "../../comments/CommentList.vue";
import { mapState } from "vuex";
import ArticleTitleDisplay from "../ArticleTitleDisplay.vue";
import VotingWidget from "../../common/VotingWidget.vue";

export default {
  components: {
    ArticleTag,
    ReferenceDisplay,
    CommentList,
    ArticleTitleDisplay,
    VotingWidget,
  },
  name: "ArticleView",
  props: ["article", "editorMode"],
  computed: {
    ...mapState("userModule", ["user"]),
    isLoggedIn: function () {
      return this.user?.isLoggedIn ?? false;
    },
    showCommentButton: function () {
      return (
        (this.isLoggedIn &&
          this.article.publicationStatus !== "DRAFT" &&
          this.article?.comments?.length === 0) ??
        false
      );
    },
    articleViewId: function () {
      return `article-view-${this.article?.id}`;
    },
    tags: function () {
      return this.article?.tags || [];
    },
    publicationDate: function () {
      const timestamp = this.article?.publicationTimeStamp;

      return timestamp ? DateTime.fromISO(timestamp)?.toISODate() : null;
    },
    references: function () {
      if (this.editorMode)
        return [...(this.article.references ?? [])].sort((a, b) =>
          a.shortDisplay < b.shortDisplay
            ? -1
            : a.shortDisplay > b.shortDisplay
            ? 1
            : 0
        );

      return [...(this.article?.references ?? [])]
        .filter((reference) => {
          const regex = new RegExp(`\\\\cite{${reference.key}.*?}`);
          console.log(`Test regex: ${regex}`);
          return regex.test(this.article.content);
        })
        .sort((a, b) =>
          a.shortDisplay < b.shortDisplay
            ? -1
            : a.shortDisplay > b.shortDisplay
            ? 1
            : 0
        );
    },
  },
  methods: {
    authorUrl: function (authorId) {
      return `/user/${authorId}`;
    },
    openCommentReplyBox: function () {
      this.$refs.commentList.openReplyBox();
    },
  },
};
</script>
