<template>
  <generic-modal ref="modal" :title="'References'">
    <div class="block" v-if="references && references.length > 0">
      <reference-display
        v-for="(reference, index) of references"
        :key="index"
        :reference="reference"
        :showDeleteButton="true"
        :showCiteButton="true"
        @delete-reference-click="deleteReference(index)"
      />
    </div>
    <div class="block">
      <form class="form">
        <div class="field">
          <label for="reference-key" class="field">
            Key (use this to insert a reference):
          </label>
          <input
            class="input"
            type="text"
            id="reference-key"
            placeholder="Einstein_PhotoElectric"
            v-model="reference.key"
          />
        </div>
        <div class="field">
          <label for="reference-short-display" class="label">
            Short display (will be displayed in the article text):
          </label>
          <input
            class="input"
            type="text"
            id="reference-short-display"
            placeholder="Einstein, 1905"
            v-model="reference.shortDisplay"
          />
        </div>
        <div class="field">
          <label for="reference-full-display" class="label">
            Full display (displayed in article references section):
          </label>
          <textarea
            class="textarea"
            id="reference-full-display"
            placeholder='Einstein, Albert. 1905 "Über einen die Erzeugung und Verwandlung des Lichtes betreffenden heuristischen Gesichtspunkt". *Annalen der Physik* **17** (6): 132-148'
            v-model="reference.fullDisplay"
          />
        </div>
        <button
          id="add-reference-button"
          class="button is-primary"
          @click="(event) => addReference(event)"
        >
          Add &nbsp;
          <font-awesome-icon icon="thumbtack" />
        </button>
      </form>
    </div>
  </generic-modal>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import GenericModal from "../common/GenericModal.vue";
import ReferenceDisplay from "../common/ReferenceDisplay.vue";
export default {
  components: { GenericModal, ReferenceDisplay },
  name: "ReferencesModal",
  data: function () {
    return {
      reference: {
        key: "",
        shortDisplay: "",
        fullDisplay: "",
      },
      references: [],
    };
  },
  computed: {
    ...mapState("editArticleModule", ["article"]),
  },
  methods: {
    ...mapMutations("editArticleModule", { updateArticle: "UPDATE" }),
    openModal: function () {
      this.$refs.modal.openModal();
      this.references = JSON.parse(
        JSON.stringify(this.article?.references ?? [])
      );
    },
    deleteReference: function (index) {
      if (!confirm(`Really delete this reference?`)) return;
      this.references.splice(index, 1);
      this.updateArticle({
        references: JSON.parse(JSON.stringify(this.references)),
      });
    },
    addReference: function (event) {
      event.preventDefault();

      this.references.push(Object.assign({}, this.reference));
      this.reference = {
        key: "",
        shortDisplay: "",
        fullDisplay: "",
      };
      this.updateArticle({
        references: JSON.parse(JSON.stringify(this.references)),
      });
    },
  },
};
</script>

<style scoped>
.display-references-container {
  overflow-x: hidden;
  overflow-y: auto;
  height: 300px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 4px;
}

.add-reference-container {
  padding: 4px 0 0 0;
  border: 1px solid black;
  border-radius: 5px;
}

#add-reference-button {
  margin: 4px 0;
}

#reference-full-display {
  resize: none;
}

.form-group {
  margin: 4px;
  padding: 4px;
  border: 1px dotted black;
}
</style>
