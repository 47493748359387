const PdfExporter = class {
  static exportToPDF(article) {
    const id = `article-view-${article.id}`;
    const element = document.getElementById(id);
    const articleHtml = element.innerHTML;

    const printWindow = window.open("", "", "height=768, width=1024");
    printWindow.document.write(
      `<html><head><title>${article.title}</title></head>`
    );

    Array.from(document.styleSheets).forEach((styleSheet) => {
      try {
        if (styleSheet.cssRules) {
          const newStyleElement = printWindow.document.createElement("style");
          Array.from(styleSheet.cssRules).forEach((rule) =>
            newStyleElement.appendChild(
              printWindow.document.createTextNode(rule.cssText)
            )
          );
          printWindow.document.head.appendChild(newStyleElement);
        } else if (styleSheet.href) {
          const newLinkElement = printWindow.document.createElement("link");
          newLinkElement.rel = "stylesheet";
          newLinkElement.href = styleSheet.href;
          printWindow.document.head.appendChild(newLinkElement);
        }
      } catch (err) {
        console.error(err);
      }
    });

    printWindow.document.write("<body >");
    printWindow.document.write(articleHtml);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  }
};

export { PdfExporter };
