<template>
  <generic-modal ref="modal" title="Drafts">
    <div class="w3-container">
      <div class="draft-summary" v-for="draft of drafts" :key="draft.id">
        <h2>{{ displayDate(draft.created) }}</h2>
        <div
          class="draft-title-display"
          v-markdown="draft.title"
          @click="loadSelectedDraft(draft.id)"
        />
        <h1 @click="deleteArticle(draft.id)">
          <inline-icon iconName="trash" :toolTipText="`Delete`" />
        </h1>
      </div>
    </div>
  </generic-modal>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions } from "vuex";
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";
import GenericModal from "../common/GenericModal.vue";
import InlineIcon from "../common/InlineIcon.vue";
export default {
  components: {
    GenericModal,
    InlineIcon,
  },
  name: "DraftsModal",
  data: function () {
    return {
      drafts: [],
      loading: false,
      error: null,
    };
  },
  methods: {
    ...mapActions("editArticleModule", ["loadDraft"]),
    openModal: function () {
      this.fetchData();
      this.$refs.modal.openModal();
    },
    closeModal: function () {
      this.$refs.modal.closeModal();
    },
    loadSelectedDraft: async function (draftId) {
      try {
        await this.loadDraft({ draftId });
        this.$toast.success("Article loaded");
      } catch (err) {
        this.$toast.error("Error loading article");
      }

      this.closeModal();
    },
    displayDate: function (aDate) {
      return DateTime.fromISO(aDate).toLocaleString(DateTime.DATE_SHORT);
    },
    deleteArticle: async function (articleId) {
      if (!confirm("Really delete article?")) return;
      const response = await fetchFromApi(`/articles/${articleId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        this.$toast.success("Article deleted");
        this.fetchData();
      } else this.$toast.error("Error deleting article");
    },
    fetchData: async function () {
      if (this.loading) return;
      this.loading = true;
      this.error = null;
      try {
        const response = await fetchFromApi("/users/drafts");
        if (response.ok) this.drafts = await response.json();
        else
          throw new Error(
            `Unexpected response from server. Status: ${response.status}`
          );
      } catch (err) {
        console.error(err);
        this.error = err.message;
        this.drafts = [];
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.draft-title-display:hover {
  background-color: var(--site-hover-background-color);
  cursor: pointer;
}

.draft-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem;
}

.draft-title-display {
  padding: 0.1rem 1rem;
}
</style>
