const customImageRule =
  /\\image{(?<alt>.+)---(?<caption>.*)---(?<attribution>.+)---(?<path>.+)}/;

const hostname = () =>
  process.env.NODE_ENV === "production"
    ? "https://www.publishscience.org/api"
    : "http://localhost:3000/api";

const imagesExtension = {
  name: "customImage",
  level: "inline",
  start: function (src) {
    return src.match(customImageRule)?.index;
  },
  tokenizer: function (src) {
    const match = src.match(customImageRule);
    if (!match || match.index > 0) return false;

    const attributionTokens = [];
    this.lexer.inlineTokens(match.groups.attribution, attributionTokens);

    const captionTokens = [];
    if (match.groups.caption)
      this.lexer.inlineTokens(match.groups.caption, captionTokens);

    return {
      type: "customImage",
      raw: match[0],
      alt: match.groups?.alt,
      path: match.groups?.path,
      attributionTokens,
      captionTokens,
    };
  },
  renderer: function (token) {
    const imgsrc = `${hostname()}${token.path}`;

    return `<div class="ps-image" style="text-align: center; position: relative;">
        <img src="${imgsrc}" alt="${token.alt}" 
        style="max-width: 90%; height: auto;" />
        <div class="ps-image-tooltip notification is-info">${this.parser.parseInline(
          token.attributionTokens
        )}</div>
        <div style="padding: 0.2em;"> ${this.parser.parseInline(
          token.captionTokens
        )} </div>
        </div>`;
  },
};

export default imagesExtension;
