<template>
  <div class="level is-mobile">
    <div class="level-left">
      <div class="level-item" v-markdown="invitation.label" />
    </div>

    <div class="level-right">
      <button
        class="button is-dark level-item"
        @click="copyInvitationText"
        title="Copy invitation text"
      >
        <font-awesome-icon icon="bookmark" />
      </button>
      <button
        class="button is-danger level-item"
        @click="emitDeleteEvent"
        title="Delete invitation"
      >
        <font-awesome-icon icon="times-circle" />
      </button>
    </div>
  </div>
</template>

<script>
import { getInvitationText } from "../../plumbing/invitations/invitationText.js";

export default {
  name: "CollaboratorInvite",
  props: ["invitation"],
  methods: {
    emitDeleteEvent: function () {
      this.$emit("delete-invitation");
    },
    copyInvitationText: async function () {
      await navigator.clipboard?.writeText(getInvitationText(this.invitation));
      this.$toast.success("Copied");
    },
  },
};
</script>
