<template>
  <form class="register-form">
    <div class="field">
      <label class="label">First name</label>
      <input class="input" type="text" v-model="firstName" />
    </div>
    <div class="field">
      <label class="label">Last name</label>
      <input class="input" type="text" v-model="lastName" />
    </div>
    <div class="field">
      <label class="label">Email</label>
      <input class="input" type="email" v-model="email" />
    </div>
    <div class="field">
      <label class="label">Password</label>
      <input class="input" type="password" v-model="password" />
    </div>
    <div class="field">
      <label class="label">Confirm password</label>
      <input class="input" type="password" v-model="secondPassword" />
    </div>
    <div class="notification is-warning">
      <p>Your password must be more that 8 characters in length.</p>
      <p>
        Click
        <a
          href="https://en.wikipedia.org/wiki/Password_strength#Common_guidelines"
          target="_blank"
          >here</a
        >
        to read about how to create a strong password.
      </p>
      <p>
        You can also use a
        <a
          href="https://en.wikipedia.org/wiki/List_of_password_managers"
          target="_blank"
          >password manager</a
        >
        :)
      </p>
    </div>
    <label class="checkbox">
      <input type="checkbox" v-model="acceptedConditions" />
      I agree to the
      <a href="/legal/termsOfService" target="_blank">terms and conditions</a>
      and <a href="/legal/termsOfService" target="_blank">privacy policy</a>.
    </label>

    <p class="notification is-info">
      You will receive a registration email with a verification link which you
      will need to click in order to complete the sign-up process
    </p>

    <google-recaptcha
      class="m-2"
      :sitekey="`6LffQlweAAAAAE3BKnCgB6o5GfZn1pCCrmaDnXGY`"
      @captcha-complete="captchaComplete"
    />

    <button
      class="button is-primary m-2 p-2"
      @click.prevent="attemptRegister"
      :disabled="!allConditionsValid"
    >
      Register
    </button>
  </form>
</template>

<script>
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";
import GoogleRecaptcha from "../common/GoogleRecaptcha.vue";
export default {
  name: "RegisterForm",
  components: { GoogleRecaptcha },
  data: function () {
    return {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      secondPassword: null,
      captchaCode: null,
      acceptedConditions: null,
    };
  },
  computed: {
    allConditionsValid: function () {
      return (
        this.acceptedConditions &&
        !!this.firstName &&
        !!this.lastName &&
        !!this.password &&
        !!this.secondPassword &&
        this.password === this.secondPassword &&
        this.password.length >= 8 &&
        !!this.captchaCode
      );
    },
  },
  methods: {
    captchaComplete: function (captchaResponse) {
      this.captchaCode = captchaResponse;
    },
    attemptRegister: async function () {
      const response = await fetchFromApi("/users/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          captchaCode: this.captchaCode,
        }),
      });

      if (response.ok) {
        this.$toast.success(
          "Registration successful! Check your email for further instructions."
        );
        this.$emit("registration-succeeded");
      } else
        this.$toast.error(
          "Registration failed. Could be something on our side, or maybe you filled the form in incorrectly. Try again maybe?"
        );
    },
  },
};
</script>
