const searchModule = {
  namespaced: true,
  state: () => ({
    searchTerms: null,
  }),
  mutations: {
    UPDATE(state, payload) {
      state.searchTerms = payload.searchTerms;
    },
  },
};

export { searchModule };
