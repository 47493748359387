<template>
  <div>
    <font-awesome-icon
      icon="edit"
      @click="setDisplayMode('editorOnly')"
      class="toggle-button"
      :class="displayMode === 'editorOnly' ? 'selectedDisplayMode' : null"
    />
    <font-awesome-icon
      v-if="sideBySideOption"
      icon="columns"
      @click="setDisplayMode('sideBySide')"
      class="toggle-button"
      :class="displayMode === 'sideBySide' ? 'selectedDisplayMode' : null"
    />
    <font-awesome-icon
      icon="eye"
      @click="setDisplayMode('viewOnly')"
      class="toggle-button"
      :class="displayMode === 'viewOnly' ? 'selectedDisplayMode' : null"
    />
  </div>
</template>

<script>
export default {
  name: "EditDisplayToggle",
  props: ["displayMode", "sideBySideOption"],
  methods: {
    setDisplayMode: function (mode) {
      this.$emit("displayModeSet", { mode });
    },
  },
};
</script>

<style scoped>
.toggle-button {
  margin: 2px;
}
.selectedDisplayMode {
  background-color: var(--site-hover-background-color);
}
</style>
