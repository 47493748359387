<template>
  <generic-modal ref="modal" :title="`Publish`">
    <form class="form" @submit.prevent="acceptConditionsAndPublish">
      <div class="field">
        <div v-markdown="article.title" />
      </div>
      <div class="field content">
        <ul>
          <li>
            I confirm that the contents of this article are the work of the
            author(s) and that all permissions relevant to its publication,
            including the consent of the author(s), and permissions related to
            images or other works included in the article, have been obtained.
          </li>
          <li>
            I confirm that the author(s) publish this work under a Creative
            Commons
            <a
              href="https://creativecommons.org/licenses/by-sa/4.0/"
              alt="Link to CC licence"
              target="_blank"
              >Attribution-ShareAlike 4.0 International (CC BY-SA 4.0)</a
            >
            Licence. This work may be used for any purpose, commercial or
            non-commercial. When it is used,
            <a
              href="https://wiki.creativecommons.org/wiki/Best_practices_for_attribution"
              alt="Link to attribution instructions"
              >attribution must be given in a reasonable manner</a
            >. I, and the listed authors, do not endorse this article for any
            particular use.
          </li>
        </ul>
      </div>

      <div class="field">
        <div class="control mx-2">
          <label class="checkbox">
            <input type="checkbox" v-model="agreed" />
            I agree
          </label>
        </div>
      </div>
      <div class="field">
        <div class="control">
          <button class="button is-success" :disabled="!agreed">Publish</button>
        </div>
      </div>
    </form>
  </generic-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import GenericModal from "../common/GenericModal.vue";
export default {
  name: "PublishModal",
  components: { GenericModal },
  data: function () {
    return {
      agreed: false,
    };
  },
  computed: {
    ...mapState("editArticleModule", ["article"]),
  },
  methods: {
    ...mapActions("editArticleModule", ["publishDraft", "saveDraft"]),
    openModal: function () {
      this.$refs.modal.openModal();
    },
    closeModal: function () {
      this.$refs?.modal?.closeModal();
    },
    acceptConditionsAndPublish: async function () {
      try {
        await this.saveDraft();
        const articleId = await this.publishDraft();
        this.$toast.success("Successfully published article");
        setTimeout(() => this.$router.push({ path: `/articles/${articleId}` }));
      } catch (err) {
        console.error(err);
        this.$toast.error("Error publishing article");
      }
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.publish-display {
  display: block;
  margin: 0.5rem 0;
  width: 100%;
}

.publish-padding {
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 5px;
}

.publish-label {
  display: flex;
  flex-flow: row;
}

.publish-margin {
  margin: 0.5rem;
  max-width: calc(100% - 1rem);
}
</style>
