<template>
  <div
    id="app"
    @mousedown="appMouseDown"
    class="is-size-5"
    :style="{ 'background-image': `url(${publicPath}Flag_of_Ukraine.svg)` }"
  >
    <MainNavBar />
    <div class="expansion-space">
      <router-view></router-view>
      <footer class="footer mt-auto mb-0">
        <div class="content has-text-centered">
          <span class="m-1">
            <a href="/legal/termsOfService" target="_blank">Terms of Service</a>
          </span>
          -
          <span class="m-1">
            <a href="/legal/privacyPolicy" target="_blank">Privacy Policy</a>
          </span>
        </div>
        <div class="m2 notification is-warning">
          This website is a work in progress. Please don't be too surprised if
          you find bugs. Once the website is no longer a beta product there'll
          be a proper bug tracker and all that stuff :)
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import MainNavBar from "./components/navigation/MainNavBar.vue";
import "katex/dist/katex.min.css";
import { mapActions, mapMutations } from "vuex";
import { initLogout } from "./plumbing/fetchwrapper/fetchWrapper";

export default {
  name: "App",
  metaInfo: function () {
    return {
      title: "PublishScience.org - Free Open Access Scientific Publishing",
      meta: [
        {
          name: "description",
          content:
            "PublishScience.org is a free website where you can publish scientific articles for peer review",
        },
        {
          name: "robots",
          content: "index, follow",
        },
        {
          property: "og:title",
          content:
            "PublishScience.org - Free Open Access Scientific Publishing",
        },
        {
          property: "og.description",
          content:
            "PublishScience.org is a free website where you can publish scientific articles for peer review",
        },
        {
          property: "og:url",
          content: "www.publishscience.org",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:site_name",
          content: "PublishScience.org",
        },
      ],
    };
  },
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  components: {
    MainNavBar,
  },
  methods: {
    ...mapMutations("userInterfaceModule", ["setPrevFocusedElement"]),
    ...mapActions("userModule", ["logout"]),
    appMouseDown: function () {
      this.setPrevFocusedElement({
        prevFocusedElement: document.activeElement,
      });
    },
  },
  created() {
    initLogout(this.logout);
  },
};
</script>

<style>
body,
html {
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
  scrollbar-width: none !important;
}

body::-webkit-scrollbar,
html::-webkit-scrollbar {
  display: none !important;
}

* {
  box-sizing: border-box;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.expansion-space {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow-x: clip;
  overflow-y: auto;
  background-color: inherit;
}

pre {
  white-space: pre-wrap !important;
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap !important;
  white-space: -o-pre-wrap !important;
  word-wrap: break-word !important;
}

.is-height-auto {
  height: auto !important;
}

.is-height-100-percent {
  height: 100% !important;
}

.has-text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

.is-whitespace-normal {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.is-whitespace-no-wrap {
  white-space: nowrap;
}

.comment-body p {
  display: inline-block !important;
}

.ps-image-tooltip {
  display: none;
  position: absolute;
  bottom: -0.2rem;
  right: 0.2rem;
}

.ps-image:hover .ps-image-tooltip {
  display: block;
}

@import "~bulma/css/bulma.css";
</style>
