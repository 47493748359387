<template>
  <div class="tile is-ancestor">
    <div v-if="articles" class="tile is-parent is-12 is-flex-wrap-wrap">
      <ArticleSummary
        v-for="item of articles"
        :article="item"
        :key="item.id"
        class="tile is-4 is-child"
      />
    </div>
  </div>
</template>

<script>
import ArticleSummary from "./ArticleSummary.vue";

export default {
  name: "ArticleList",
  props: ["articles"],
  components: {
    ArticleSummary,
  },
};
</script>
