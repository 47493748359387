import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";

const emptyArticle = {
  id: null,
  title: "",
  abstract: "",
  content: "",
  references: null,
  tags: null,
  authors: null,
  category: "ARTICLE",
};

const editArticleModule = {
  namespaced: true,
  state: () => ({
    article: Object.assign({}, emptyArticle),
  }),
  mutations: {
    CLEAR: (state) => {
      state.article = Object.assign({}, emptyArticle);
    },
    LOAD: (state, payload) => {
      state.article = Object.assign({}, payload?.article);
    },
    UPDATE: (state, articleUpdate) => {
      Object.assign(state.article, articleUpdate);
    },
  },
  actions: {
    loadDraft: async (context, payload) => {
      const { draftId } = payload;
      const response = await fetchFromApi(`/articles/${draftId}`);
      if (response.ok) {
        const article = await response.json();
        context.commit("CLEAR");
        context.commit("LOAD", { article });
      } else throw new Error("Could not load draft");
    },
    saveDraft: async (context) => {
      const response = await fetchFromApi(`/articles/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(context.state.article),
      });
      if (response.ok) {
        const json = await response.json();
        context.commit("UPDATE", json);
      } else throw new Error("Could not save draft");
    },
    publishDraft: async (context) => {
      const articleId = context.state.article.id;
      const response = await fetchFromApi(`/articles/publish/${articleId}`, {
        method: "POST",
      });
      if (response.ok) {
        context.commit("CLEAR");
        return articleId;
      } else throw new Error("Could not publish draft");
    },
    createNew: ({ commit }) => {
      commit("CLEAR");
      const article = Object.assign({}, emptyArticle);
      article.title = "New article";
      article.category = "ARTICLE";
      commit("UPDATE", article);
    },
    createReview: ({ commit }, payload) => {
      commit("CLEAR");
      const article = Object.assign({}, emptyArticle);
      article.title = `${payload.article.title} - Review`;
      article.category = "REVIEW";
      article.parentId = payload.article.id;
      commit("UPDATE", article);
    },
    createRevision: ({ commit }, payload) => {
      commit("CLEAR");
      let article = Object.assign({}, emptyArticle);
      article = Object.assign({}, JSON.parse(JSON.stringify(payload.article)));
      article.parentId = payload.article.id;
      article.id = null;
      article.comments = null;
      for (const reference of article.references) reference.id = null;
      commit("UPDATE", article);
    },
  },
};

export { editArticleModule };
