function getInvitationText(invitation) {
  const senderName = `${invitation.sender?.firstName} ${invitation.sender?.lastName}`;
  const inviteUrl = `https://www.publishscience.org/invitation/accept/${invitation.id}`;
  return `${senderName} invited you to edit a PublishScience.org article titled:
  "${invitation.title}"
To accept the invitation, please navigate to 
  ${inviteUrl}
and follow the on-screen instructions. 

If the link doesn't work you can try copying the url into your browser navigation bar :)

PublishScience.org is an experimental website which allows you to read, review, and publish scientific articles for free.`;
}

export { getInvitationText };
