<template>
  <div class="columns p-4 is-height-100-percent">
    <div class="column is-6">
      <error-display :error="error" v-if="error" />
      <waiting-loader v-if="loading" />
      <article-view
        v-if="!loading && parent"
        :article="parent"
        :editorMode="true"
      />
    </div>
    <div class="column is-6">
      <edit-display-toggle
        :sideBySideOption="false"
        :displayMode="displayMode"
        @displayModeSet="(event) => setDisplayMode(event)"
      />
      <article-editor v-if="showEditor" />
      <article-view :article="article" v-if="showView" :editorMode="true" />
    </div>
  </div>
</template>

<script>
import ArticleView from "../articles/view/ArticleView.vue";
import ErrorDisplay from "../common/ErrorDisplay.vue";
import WaitingLoader from "../common/WaitingLoader.vue";
import EditDisplayToggle from "../common/EditDisplayToggle.vue";
import ArticleEditor from "./ArticleEditor.vue";
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";
import { mapState } from "vuex";

export default {
  name: "EditReview",
  components: {
    ArticleView,
    WaitingLoader,
    ErrorDisplay,
    EditDisplayToggle,
    ArticleEditor,
  },
  data: function () {
    return {
      loading: false,
      error: null,
      parent: null,
      displayMode: "editorOnly",
    };
  },
  computed: {
    ...mapState("editArticleModule", ["article"]),
    showEditor: function () {
      return this.displayMode !== "viewOnly";
    },
    showView: function () {
      return this.displayMode !== "editorOnly";
    },
  },
  methods: {
    fetchData: async function () {
      if (this.loading) return;
      this.loading = true;
      this.error = null;
      try {
        const parentId = this.article.parentId;
        const response = await fetchFromApi(`/articles/${parentId}`);
        if (response.ok) this.parent = await response.json();
        else
          throw new Error(
            `Unexpected response from server: ${response.status}`
          );
      } catch (err) {
        this.error = err;
        console.error(err);
        this.parent = null;
      } finally {
        this.loading = false;
      }
    },
    setDisplayMode: function (event) {
      this.displayMode = event.mode;
    },
  },
  mounted: function () {
    this.fetchData();
  },
};
</script>
