import Vue from "vue";
import VueKatex from "vue-katex";

const addKatex = () => {
  Vue.use(VueKatex, {
    globalOptions: {
      displayMode: true,
      throwOnError: false,
    },
  });
};

export { addKatex };
