import Vue from "vue";
import Vuex from "vuex";
import createMultiTabState from "vuex-multi-tab-state";
import { userModule } from "./modules/userModule.js";
import { userInterfaceModule } from "./modules/userInterfaceModule.js";
import { editArticleModule } from "./modules/editArticleModule.js";
import { searchModule } from "./modules/searchModule.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    userModule,
    userInterfaceModule,
    editArticleModule,
    searchModule,
  },
  plugins: [createMultiTabState()],
  strict: process.env.NODE_ENV !== "production",
});

export { store };
