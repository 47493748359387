<template>
  <generic-modal ref="modal" :title="`Invite collaborators`">
    <div class="content m-2">
      <p>
        Create an invitation to edit this article, then copy it and send it to
        your intended collaborator with via any means (email, whatsapp etc.).
        They will need to login to their PublishScience.org profile in order to
        accept it.
      </p>

      <ul>
        <li>
          Once your colleague accepts the invitation they are an author and will
          be able to edit or publish the article.
        </li>
        <li>Invitations expire after 24 hours and a single use.</li>
      </ul>
    </div>

    <div class="card m-2">
      <div class="card-header p-2">Pending invitations</div>
      <div class="card-content">
        <collaborator-invite
          v-for="(invitation, idx) of invitations"
          :key="idx"
          :invitation="invitation"
          @delete-invitation="deleteInvitation(idx)"
        />
      </div>
    </div>

    <form class="form m-2">
      <div class="field">
        <label class="label">Label (e.g. Recipient's name)</label>
        <input
          class="input"
          type="text"
          v-model="newInvitation.label"
          title="Label for your records, e.g. recipient's name"
        />
      </div>
      <button class="button is-primary" @click.prevent="createInvitation">
        Create invitation
      </button>
    </form>
  </generic-modal>
</template>

<script>
import { getInvitationText } from "../../plumbing/invitations/invitationText.js";
import { mapState } from "vuex";
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";
import GenericModal from "../common/GenericModal.vue";
import CollaboratorInvite from "./CollaboratorInvite.vue";
export default {
  components: { GenericModal, CollaboratorInvite },
  name: "CollaboratorModal",
  data: function () {
    return {
      error: null,
      loading: false,
      invitations: [],
      newInvitation: {
        label: null,
        expiryDate: null,
      },
    };
  },
  computed: {
    ...mapState("editArticleModule", ["article"]),
  },
  methods: {
    openModal: function () {
      this.$refs.modal.openModal();
    },
    createInvitation: async function () {
      console.log("Creating invitation");
      const response = await fetchFromApi(`/invitations/${this.article.id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          label: this.newInvitation.label,
        }),
      });
      if (response.ok) {
        this.$toast.success("Successfully created invitation.");
        this.fetchData();
        try {
          await navigator.clipboard?.writeText(
            getInvitationText(await response.json())
          );
          this.$toast.success("Copied invitation text to clipboard.");
        } catch (err) {
          this.$toast.error("Error creating invitation");
        }
      } else this.$toast.error("Error creating invitation");
    },
    deleteInvitation: async function (idx) {
      if (!confirm(`Really delete this invitation?`)) return;
      const deleteId = this.invitations[idx].id;
      const response = await fetchFromApi(`/invitations/specific/${deleteId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        this.$toast.success("Successfully deleted invitation");
        this.fetchData();
      } else this.$toast.error("Error deleting invitation");
    },
    fetchData: async function () {
      if (this.loading) return;
      if (!this.article?.id) return;

      this.loading = true;
      this.error = null;
      try {
        const response = await fetchFromApi(`/invitations/${this.article.id}`);
        if (response.ok) this.invitations = await response.json();
        else
          throw new Error(
            `Unexpected response from server: ${response.status}`
          );
      } catch (err) {
        this.error = err.message;
        console.error(err);
        this.invitations = null;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted: function () {
    this.fetchData();
  },
};
</script>
