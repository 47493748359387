<template>
  <div :class="loading ? 'expansion-space' : null">
    <waiting-loader v-if="loading" />
    <div v-else>
      <error-display :error="error" v-if="error" />
      <article-list :articles="articles" />
      <div class="m-3">
        <button
          class="button is-dark m-1"
          :disabled="page === 0"
          @click.prevent="decrementPage"
        >
          Previous
        </button>
        <button
          class="button is-dark m-1"
          :disabled="(articles || []).length < articlesPerPage"
          @click.prevent="incrementPage"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import WaitingLoader from "../common/WaitingLoader.vue";
import ArticleList from "./ArticleList.vue";
import ErrorDisplay from "../common/ErrorDisplay.vue";
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";

export default {
  name: "ArticlesTab",
  components: {
    ArticleList,
    WaitingLoader,
    ErrorDisplay,
  },
  data: function () {
    return {
      articles: null,
      error: null,
      loading: false,
      page: 0,
      articlesPerPage: 25,
    };
  },
  computed: {
    ...mapState("userModule", ["user"]),
    ...mapState("searchModule", ["searchTerms"]),
  },
  methods: {
    ...mapMutations("searchModule", { updateSearchTerms: "UPDATE" }),
    decrementPage: function () {
      this.page--;
      this.fetchData();
    },
    incrementPage: function () {
      this.page++;
      this.fetchData();
    },
    getSearchUrl: function () {
      let baseUrl = `/search/articles`;
      baseUrl += `?limit=${this.articlesPerPage}&offset=${
        this.page * this.articlesPerPage
      }`;

      return this.searchTerms
        ? `${baseUrl}&searchTerms=${this.searchTerms}`
        : baseUrl;
    },
    fetchData: async function () {
      if (this.loading) return;
      this.loading = true;
      this.error = null;
      try {
        const response = await fetchFromApi(this.getSearchUrl());
        if (response.ok) this.articles = await response.json();
        else
          throw new Error(
            `Unexpected response from server: ${response.status}`
          );
      } catch (err) {
        console.error(err);
        this.error = err.message;
        this.articles = null;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.updateSearchTerms({ searchTerms: null });
    this.fetchData();
  },
  watch: {
    searchTerms: "fetchData",
  },
};
</script>

<style scoped>
.tab-list {
  margin-bottom: 4px;
}
</style>
