<template>
  <nav class="navbar is-info">
    <div class="navbar-brand">
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="edit-toolbar-menu"
        @click="hamburgerExpanded = !hamburgerExpanded"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div
      id="edit-toolbar-menu"
      class="navbar-menu"
      :class="!!hamburgerExpanded ? 'is-active' : ''"
    >
      <div class="navbar-start">
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">Drafts</a>

          <div class="navbar-dropdown">
            <a
              class="navbar-item"
              href="#"
              @click="fireEvent(`create-new-article`)"
            >
              <font-awesome-icon icon="edit" class="mr-2" />
              New
            </a>
            <a
              class="navbar-item"
              href="#"
              @click="fireEvent(`save-article`)"
              v-if="article && article.id"
            >
              <font-awesome-icon icon="save" class="mr-2" />
              Save
            </a>
            <a class="navbar-item" href="#" @click="fireEvent(`open-article`)">
              <font-awesome-icon icon="folder-open" class="mr-2" />
              Open
            </a>
            <template
              v-if="article && article.id && article.category !== 'REVIEW'"
            >
              <hr class="navbar-divider" />
              <a
                class="navbar-item"
                href="#"
                @click="fireEvent('add-collaborator')"
              >
                <font-awesome-icon icon="user" class="mr-2" />
                Invite collaborator
              </a>
            </template>
            <template v-if="article && article.id">
              <hr class="navbar-divider" />
              <a
                class="navbar-item"
                href="#"
                @click="fireEvent(`publish-article`)"
              >
                <font-awesome-icon icon="paper-plane" class="mr-2" />
                Publish
              </a>
            </template>
          </div>
        </div>

        <div
          class="navbar-item has-dropdown is-hoverable"
          v-if="article && article.id"
        >
          <a class="navbar-link">Text</a>

          <div class="navbar-dropdown">
            <a class="navbar-item" href="#" @click="fireEvent(`heading-text`)">
              <span class="mr-2 has-text-weight-bold">H</span>
              Heading
            </a>
            <a class="navbar-item" href="#" @click="fireEvent(`bold-text`)">
              <font-awesome-icon icon="bold" class="mr-2" />
              Bold
            </a>
            <a class="navbar-item" href="#" @click="fireEvent(`italic-text`)">
              <font-awesome-icon icon="italic" class="mr-2" />
              Italic
            </a>
          </div>
        </div>

        <div
          class="navbar-item has-dropdown is-hoverable"
          v-if="article && article.id"
        >
          <a class="navbar-link">Insert</a>

          <div class="navbar-dropdown">
            <a
              class="navbar-item"
              href="#"
              @click="fireEvent(`insert-formula`)"
            >
              <font-awesome-icon icon="square-root-alt" class="mr-2" />
              Formula
            </a>
            <a class="navbar-item" href="#" @click="fireEvent(`insert-table`)">
              <font-awesome-icon icon="table" class="mr-2" />
              Table
            </a>
            <a
              class="navbar-item"
              href="#"
              @click="fireEvent(`insert-hyperlink`)"
            >
              <font-awesome-icon icon="link" class="mr-2" />
              Hyper-link
            </a>
            <a class="navbar-item" href="#" @click="fireEvent(`insert-image`)">
              <font-awesome-icon icon="image" class="mr-2" />
              Images
            </a>
          </div>
        </div>

        <div
          class="navbar-item has-dropdown is-hoverable"
          v-if="article && article.id"
        >
          <a class="navbar-link">Metadata</a>

          <div class="navbar-dropdown">
            <a
              class="navbar-item"
              href="#"
              @click="fireEvent(`edit-references`)"
            >
              <font-awesome-icon icon="thumbtack" class="mr-2" />
              References
            </a>
            <a class="navbar-item" href="#" @click="fireEvent(`edit-tags`)">
              <font-awesome-icon icon="tags" class="mr-2" />
              Tags
            </a>
          </div>
        </div>
      </div>

      <div class="navbar-end" v-if="article && article.id">
        <a
          class="navbar-item"
          v-if="!!article && article.id"
          :href="`/articles/${article.id}`"
          target="_blank"
        >
          <font-awesome-icon icon="eye" class="mr-2" />
          Preview
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EditToolbar",
  data: function () {
    return {
      hamburgerExpanded: false,
    };
  },
  computed: {
    ...mapState("userModule", ["user"]),
    ...mapState("editArticleModule", ["article"]),
  },
  methods: {
    fireEvent(eventName, event) {
      this.$emit(eventName, event);
    },
  },
};
</script>

<style scoped>
navbar-item:hover {
  background-color: bisque;
}
</style>
