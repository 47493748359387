<template>
  <div id="user-summary-container">
    <div id="user-card" v-on:click="toggleLogin()">
      <h4 id="username-display">
        <font-awesome-icon icon="user"></font-awesome-icon>
        {{ username ? username : "Login / Register (free)" }}
        <span>{{ !!user && user.isLoggedIn ? user.reputation : null }}</span>
      </h4>
    </div>
    <login-modal ref="loginModal" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import LoginModal from "./LoginModal.vue";

export default {
  components: { LoginModal },
  name: "UserSummary",
  computed: {
    ...mapState("userModule", ["user"]),
    ...mapGetters("userModule", ["username"]),
  },
  methods: {
    toggleLogin: function () {
      if (!this.user?.isLoggedIn) {
        this.$refs.loginModal.openModal();
        return;
      }

      this.$router.push(`/user/${this.user?.id}`);
    },
  },
};
</script>

<style scoped>
.h4 {
  display: inline-block;
}

#user-card {
  text-align: center;
  cursor: pointer;
  padding: 4px;
}
</style>
