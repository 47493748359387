<template>
  <div>
    <div class="level my-1">
      <div class="level-left">
        <button
          class="button level-item mx-1"
          :class="
            !userIsCurrent || currentTab === 'authoredArticles'
              ? 'is-primary'
              : 'is-outlined'
          "
          @click="currentTab = 'authoredArticles'"
        >
          <font-awesome-icon icon="newspaper" class="m-1" />Authored articles
        </button>
        <button
          v-if="userIsCurrent"
          class="button level-item mx-1"
          :class="currentTab === 'myFavourites' ? 'is-primary' : 'is-outlined'"
          @click="currentTab = 'myFavourites'"
        >
          <font-awesome-icon icon="star" class="m-1" />Favourites
        </button>
      </div>
      <div class="level-right mx-1">
        <button v-if="userIsCurrent" class="button level-item" @click="logout">
          <font-awesome-icon icon="sign-out-alt" class="m-1" />Log out
        </button>
        <button
          v-if="userIsCurrent"
          class="button level-item mx-1"
          @click="openSettingsModal"
        >
          <font-awesome-icon icon="cogs" class="m-1" />Settings
        </button>
      </div>
    </div>
    <div>
      <waiting-loader v-if="loading" />
      <div v-else>
        <error-display :error="error" v-if="error" />
        <article-list :articles="articles" />
      </div>
    </div>
    <user-settings-modal ref="settingsModal" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import UserSettingsModal from "./UserSettingsModal.vue";
import WaitingLoader from "../common/WaitingLoader.vue";
import ArticleList from "../articles/ArticleList.vue";
import ErrorDisplay from "../common/ErrorDisplay.vue";
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";

export default {
  components: { UserSettingsModal, WaitingLoader, ErrorDisplay, ArticleList },
  name: "UserListView",
  data: function () {
    return {
      currentTab: "authoredArticles",
      articles: null,
      error: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions("userModule", ["logout"]),
    ...mapMutations("searchModule", { updateSearchTerms: "UPDATE" }),
    openSettingsModal: function () {
      this.$refs.settingsModal.openModal();
    },
    getSearchUrl: function () {
      const baseUrl =
        this.currentTab === "myFavourites" && this.userIsCurrent
          ? `/search/favourites`
          : `/search/user-articles/${this.$route.params.userId}`;
      return this.searchTerms
        ? `${baseUrl}?searchTerms=${this.searchTerms}`
        : baseUrl;
    },
    onTabChange: function () {
      this.updateSearchTerms({ searchTerms: null });
      this.fetchData();
    },
    fetchData: async function () {
      if (this.loading) return;
      this.loading = true;
      this.error = null;
      try {
        const response = await fetchFromApi(this.getSearchUrl());
        if (response.ok) this.articles = await response.json();
        else
          throw new Error(
            `Unexpected response from server: ${response.status}`
          );
      } catch (err) {
        console.error(err);
        this.error = err.message;
        this.articles = null;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState("userModule", ["user"]),
    ...mapState("searchModule", ["searchTerms"]),
    userIsCurrent: function () {
      return (
        this.$route.params.userId === this.user?.id && this.user?.isLoggedIn
      );
    },
  },
  mounted() {
    this.updateSearchTerms({ searchTerms: null });
    this.fetchData();
  },
  watch: {
    currentTab: "onTabChange",
    searchTerms: "fetchData",
    userIsCurrent: "fetchData",
  },
};
</script>
