<template>
  <generic-modal ref="modal" :title="'Authorise access'">
    <div class="tabs is-large">
      <ul>
        <li
          :class="showLoginForm ? 'is-active' : null"
          class="p-2 m-1"
          @click="showLoginForm = true"
        >
          Login
        </li>
        <li
          :class="!showLoginForm ? 'is-active' : null"
          class="p-2 m-1"
          @click="showLoginForm = false"
        >
          Register
        </li>
      </ul>
    </div>
    <login-form v-if="showLoginForm" @login-success="closeModal" />
    <register-form v-if="!showLoginForm" @registration-succeeded="closeModal" />
  </generic-modal>
</template>

<script>
import GenericModal from "../common/GenericModal.vue";
import LoginForm from "./LoginForm.vue";
import RegisterForm from "./RegisterForm.vue";
export default {
  name: "LoginModal",
  components: {
    GenericModal,
    LoginForm,
    RegisterForm,
  },
  data: function () {
    return {
      showLoginForm: true,
    };
  },
  methods: {
    openModal: function () {
      this.$refs.modal.openModal();
    },
    closeModal: function () {
      this.$refs.modal.closeModal();
    },
    setLoginFormVisible: function () {
      this.showLoginForm = true;
    },
    setRegisterFormVisible: function () {
      this.showLoginForm = false;
    },
  },
};
</script>
