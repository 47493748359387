<template>
  <div>
    <form>
      <div class="field">
        <label class="label">Old password:</label>
        <input class="input" type="password" v-model="oldPassword" />
      </div>
      <div class="field">
        <label class="label">New password:</label>
        <input class="input" type="password" v-model="newPassword" />
      </div>
      <div class="field">
        <label class="label">Repeat new password:</label>
        <input class="input" type="password" v-model="repeatNewPassword" />
      </div>
      <button
        class="button is-outlined is-danger m-2 p-1"
        @click.prevent="changePassword"
        :disabled="buttonDisabled"
      >
        Change password
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SecurityForm",
  data: function () {
    return {
      oldPassword: null,
      newPassword: null,
      repeatNewPassword: null,
    };
  },
  methods: {
    ...mapActions("userModule", ["updatePassword"]),
    changePassword: async function () {
      try {
        await this.updatePassword({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        });
        this.$toast.success("Updated password successfully");
        this.$emit("close-modal");
      } catch (err) {
        this.$toast.error("Error updating password");
      }
    },
  },
  computed: {
    buttonDisabled: function () {
      return !(
        this.oldPassword &&
        this.newPassword &&
        this.repeatNewPassword &&
        this.newPassword === this.repeatNewPassword
      );
    },
  },
};
</script>
