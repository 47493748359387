import Vue from "vue";
import { marked } from "marked";
import blockLatexExtension from "./blockLatexExtension";
import citationsExtension from "./citationsExtension";
import inlineLatexExtension from "./inlineLatexExtension";
import styleHelper from "./styleHelper";
import DOMPurify from "dompurify";
import imagesExtension from "./imagesExtension";

const initMarked = () => {
  marked.use(styleHelper, {
    extensions: [
      inlineLatexExtension,
      blockLatexExtension,
      citationsExtension,
      imagesExtension,
    ],
  });

  Vue.directive("markdown", function (el, binding) {
    if (typeof binding.value === "string" || binding.value instanceof String) {
      const markdown = binding.value;
      el.innerHTML = DOMPurify.sanitize(marked(markdown));
      return;
    }

    const { markdown, references } = binding.value;
    citationsExtension.references = references || [];
    el.innerHTML = DOMPurify.sanitize(marked(markdown));
  });
};

export { initMarked };
