const userInterfaceModule = {
  namespaced: true,
  state: () => ({
    prevFocusedElement: null,
  }),
  mutations: {
    setPrevFocusedElement(state, payload) {
      state.prevFocusedElement = payload.prevFocusedElement;
    },
  },
};

export { userInterfaceModule };
