import "es6-promise/auto";
import Vue from "vue";
import Tooltip from "vue-directive-tooltip";
import VueMeta from "vue-meta";
import Toast from "vue-toastification";

import App from "./App.vue";

import { addKatex } from "./config/katexconfig";
import { addIcons } from "./config/icons";
import { router } from "./config/routes";
import { store } from "./data/store";
import { initMarked } from "./plumbing/marked/extendMarked";

import "vue-toastification/dist/index.css";
import "vue-directive-tooltip/dist/vueDirectiveTooltip.css";

Vue.config.productionTip = false;

const toastOptions = {};

Vue.use(Tooltip);
Vue.use(VueMeta);
Vue.use(Toast, toastOptions);
addIcons();
addKatex();
initMarked();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
