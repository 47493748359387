<template>
  <div>
    <div v-if="showReplyBox && isLoggedIn">
      <form class="">
        <input ref="replyInput" type="text" class="input" v-model="replyText" />
        <button
          class="button is-outlined is-info m-1"
          @click.prevent="saveReplyToComment"
        >
          OK
        </button>
        <button class="button is-outlined is-danger m-1" @click="closeReplyBox">
          Cancel
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "CommentReplyBox",
  props: ["comments", "parentId"],
  data: function () {
    return {
      replyText: null,
      showReplyBox: false,
      error: null,
    };
  },
  computed: {
    ...mapState("userModule", ["user"]),
    ...mapGetters("userModule", ["username"]),
    isLoggedIn: function () {
      return this.user?.isLoggedIn ?? false;
    },
  },
  methods: {
    openReplyBox: function () {
      this.showReplyBox = true;
      this.replyText = null;
      setTimeout(() => {
        const elem = this.$refs?.replyInput;
        elem?.scrollIntoView();
        elem?.focus();
      }, 0);
    },
    closeReplyBox: function () {
      this.showReplyBox = false;
      this.replyText = null;
    },
    saveReplyToComment: async function () {
      const payload = {
        parentId: this.parentId,
        content: this.replyText,
      };

      const response = await fetchFromApi("/comments", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        this.error = "Failed to create comment";
        return;
      }

      const newComment = await response.json();
      this.$emit("new-comment", newComment);

      this.closeReplyBox();
    },
  },
};
</script>
