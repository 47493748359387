<template>
  <div>
    <h2>Contact details</h2>
  </div>
</template>

<script>
export default {
  name: "ContactDetailsForm",
};
</script>
