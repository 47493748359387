<template>
  <div class="box media my-1" :id="`psref-${reference.key}`">
    <div class="media-content content" v-markdown="reference.fullDisplay"></div>
    <div class="media-right">
      <button
        class="button is-dark m-2"
        @click="copyCitation"
        v-tooltip="`Copy citation`"
        v-if="showCiteButton"
      >
        <font-awesome-icon icon="bookmark" />
      </button>
      <button
        class="button is-danger m-2"
        @click="emitDeleteEvent"
        v-tooltip="`Delete reference`"
        v-if="showDeleteButton"
      >
        <font-awesome-icon icon="times-circle" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReferenceDisplay",
  props: ["reference", "showDeleteButton", "showCiteButton"],
  methods: {
    emitDeleteEvent: function () {
      this.$emit("delete-reference-click");
    },
    copyCitation: async function () {
      await navigator.clipboard?.writeText(
        `\\cite{${this.reference.key}:your custom text here}`
      );
      this.$toast.success("Copied");
    },
  },
};
</script>

<style scoped>
.reference-display-container {
  display: flex;
  flex-flow: row;
}

.cite-reference-icon {
  cursor: pointer;
}

.reference-display {
  flex: 1 1 auto;
}

.delete-reference-icon {
  cursor: pointer;
}
</style>
