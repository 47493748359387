<template>
  <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <h3>
          <font-awesome-icon icon="flask"></font-awesome-icon>&nbsp;
          publishscience.org
        </h3>
      </a>
      <a
        role="button"
        class="navbar-burger is-large"
        :class="!!hamburgerExpanded ? 'is-active' : ''"
        aria-label="menu"
        aria-expanded="false"
        data-target="publishScienceMenu"
        @click="hamburgerExpanded = !hamburgerExpanded"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      id="publishScienceMenu"
      class="navbar-menu ps-flex-display"
      :class="!!hamburgerExpanded ? 'is-active' : ''"
    >
      <div class="navbar-start ps-full-width">
        <search-bar class="navbar-item is-expanded" />
      </div>
      <div class="navbar-end">
        <a class="navbar-item has-text-centered" href="/">
          <h4><font-awesome-icon icon="eye"></font-awesome-icon>&nbsp; Read</h4>
        </a>
        <a class="navbar-item has-text-centered" href="/write">
          <h4>
            <font-awesome-icon icon="edit"></font-awesome-icon>&nbsp; Write
          </h4>
        </a>
        <user-summary class="navbar-item" />
      </div>
    </div>
  </nav>
</template>

<script>
import SearchBar from "./SearchBar.vue";
import UserSummary from "../user/UserSummary.vue";

export default {
  name: "MainNavBar",
  components: {
    SearchBar,
    UserSummary,
  },
  data: function () {
    return {
      hamburgerExpanded: false,
    };
  },
};
</script>

<style scoped></style>
