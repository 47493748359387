<template>
  <div v-if="!!comments">
    <div>
      <div v-for="(comment, index) of comments" :key="index">
        <user-comment
          :comment="comment"
          @comment-reply="onReplyClicked(index)"
        />
      </div>
    </div>
    <comment-reply-box
      ref="replyBox"
      :parentId="parentId"
      @new-comment="updateComments"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import UserComment from "./UserComment.vue";
import CommentReplyBox from "./CommentReplyBox.vue";
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";

export default {
  name: "CommentList",
  props: ["parentId"],
  components: {
    UserComment,
    CommentReplyBox,
  },
  data: function () {
    return {
      loading: false,
      comments: [],
      error: null,
      replyText: null,
      showReplyBox: false,
    };
  },
  computed: {
    ...mapState("userModule", ["user"]),
    isLoggedIn: function () {
      return this.user?.isLoggedIn ?? false;
    },
  },
  methods: {
    onReplyClicked: function (index) {
      this.$refs.commentLists[index].openReplyBox();
    },
    openReplyBox: function () {
      this.$refs.replyBox.openReplyBox();
    },
    updateComments: function (newComment) {
      this.comments.push(newComment);
    },
    fetchData: async function () {
      if (this.loading || !this.parentId) return;
      this.loading = true;
      this.error = null;
      this.comments = [];
      try {
        const response = await fetchFromApi(`/comments/${this.parentId}`);
        this.comments = await response.json();
      } catch (err) {
        console.error(err);
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted: function () {
    this.fetchData();
  },
};
</script>
