import Vue from "vue";
import VueRouter from "vue-router";
import ArticlesTab from "../components/articles/ArticlesTab";
import ArticleFullDisplay from "../components/articles/view/ArticleFullDisplay";
import UserListView from "../components/user/UserListView";
import NavigationError from "../components/navigation/NavigationError";
import ForgotPasswordForm from "../components/user/ForgotPasswordForm";
import WriteArticle from "../components/editor/WriteArticle";
import VerificationForm from "../components/user/VerificationForm";
import BasicMarkdown from "../components/common/BasicMarkdown";
import AcceptInvitationForm from "../components/invitations/AcceptInvitationForm";

Vue.use(VueRouter);

const routes = [
  { name: "home", path: "/", component: ArticlesTab },
  {
    name: "readArticle",
    path: "/articles/:articleId",
    component: ArticleFullDisplay,
    props: true,
  },
  { name: "writeArticle", path: "/write", component: WriteArticle },
  {
    name: "userArea",
    path: "/user/:userId",
    component: UserListView,
  },
  {
    name: "forgotPasswordRoute",
    path: "/forgotPassword",
    component: ForgotPasswordForm,
  },
  {
    name: "acceptInvitationRoute",
    path: "/invitation/accept/:invitationId",
    props: true,
    component: AcceptInvitationForm,
  },
  {
    name: "verifyUserRoute",
    path: "/verify/:verificationCode",
    component: VerificationForm,
  },
  {
    name: "legalRoute",
    path: "/legal/:policyName",
    props: true,
    component: BasicMarkdown,
  },
  {
    name: "privacyPolicyRoute",
    path: "/privacyPolicy",
    component: null,
  },
  { name: "unknownPath", path: "*", component: NavigationError },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export { router };
