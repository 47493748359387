<template>
  <generic-modal ref="modal" :title="'Article Tags'">
    <div class="add-tag-display">
      <article-tag
        v-for="(tag, index) of tags"
        :key="index"
        :tag="tag"
        :showDeleteButton="true"
        @delete-tag-click="deleteTag(index)"
      />
    </div>
    <form class="add-tag-display">
      <label class="add-tag-display">
        Add tag:
        <input
          type="text"
          class="add-tag-display add-tag-padding"
          v-model="addTagText"
        />
      </label>

      <button class="add-tag-padding" @click="(event) => addTag(event)">
        Add &nbsp;
        <font-awesome-icon icon="tag" />
      </button>
    </form>
  </generic-modal>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ArticleTag from "../common/ArticleTag.vue";
import GenericModal from "../common/GenericModal.vue";
export default {
  components: { ArticleTag, GenericModal },
  name: "ArticleTagsModal",
  data: function () {
    return {
      tags: [],
      addTagText: "",
    };
  },
  computed: {
    ...mapState("editArticleModule", ["article"]),
  },
  methods: {
    ...mapMutations("editArticleModule", { updateArticle: "UPDATE" }),
    openModal: function () {
      this.$refs.modal.openModal();
      this.tags = JSON.parse(JSON.stringify(this.article?.tags ?? []));
    },
    deleteTag: function (index) {
      this.tags.splice(index, 1);
      this.updateArticle({ tags: JSON.parse(JSON.stringify(this.tags)) });
    },
    addTag: function (event) {
      event.preventDefault();
      if (this.addTagText && this.addTagText.length > 0)
        this.tags.push(this.addTagText);
      this.addTagText = "";
      this.updateArticle({ tags: JSON.parse(JSON.stringify(this.tags)) });
    },
  },
};
</script>

<style scoped>
.add-tag-display {
  display: block;
  margin: 0.5rem 0;
}

.add-tag-padding {
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 5px;
}
</style>
