<template>
  <div>
    <div
      class="
        button
        is-dark
        p-2
        is-large is-outlined is-fullwidth is-block is-height-auto
      "
      @click="$router.push(`/articles/${review.id}`)"
    >
      <div class="p1 is-block is-clipped has-text-overflow-ellipsis">
        <font-awesome-icon :icon="sentimentIcon" />
        <span class="mx-2">Review</span>
        <span class="mx-2">{{ review.author.name }}</span>
      </div>
      <div
        class="p-1 is-size-7 is-block is-whitespace-normal"
        v-markdown="review.abstract"
      />
      <div class="level is-mobile">
        <div class="level-left is-size-6">
          <div class="level-item">
            <font-awesome-icon icon="heart" class="mx-2" />
            {{ review.upvotes }}
          </div>
          <div class="level-item">
            <font-awesome-icon icon="heart-broken" class="mx-2" />
            {{ review.downvotes }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewSummary",
  props: ["review"],
  computed: {
    sentimentIcon: function () {
      return this.review?.sentiment === "POSITIVE"
        ? "trophy"
        : this.review?.sentiment === "NEGATIVE"
        ? "thumbs-down"
        : "star-half-alt";
    },
  },
};
</script>
