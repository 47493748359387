<template>
  <div @keydown="processKeyDown" class="expansion-space">
    <edit-toolbar
      @create-new-article="onCreateNewArticle(true)"
      @open-article="openArticle"
      @save-article="saveArticle"
      @heading-text="onHeadingText"
      @bold-text="onBoldText"
      @italic-text="onItalicText"
      @insert-formula="onInsertFormula"
      @insert-table="onInsertTable"
      @insert-image="onInsertImage"
      @insert-hyperlink="onInsertHyperlink"
      @edit-references="onEditReferences"
      @edit-tags="onEditTags"
      @publish-article="onPublish"
      @add-collaborator="onAddCollaborator"
    />
    <div class="expansion-space">
      <div :hidden="!article || !article.id">
        <div v-if="article.category === 'REVIEW'" class="field control">
          <label for="sentiment-dropdown" class="label">Sentiment:</label>
          <div class="select is-normal">
            <select
              id="sentiment-dropdown"
              :value="article.reviewSentiment"
              @change.prevent="updateSentiment"
            >
              <option value="NEUTRAL">OK but needs work</option>
              <option value="POSITIVE">Good work</option>
              <option value="NEGATIVE">There are problems</option>
            </select>
          </div>
        </div>

        <div
          v-if="isBlogger && article.category !== 'REVIEW'"
          class="field control"
        >
          <label for="category-dropdown" class="label">Category:</label>
          <div class="select is-normal">
            <select
              id="category-dropdown"
              :value="article.category"
              @change.prevent="updateCategory"
            >
              <option value="ARTICLE">Article</option>
              <option value="BLOG">Blog Post</option>
            </select>
          </div>
        </div>

        <div class="field">
          <label for="editor-title-input" class="label">Title:</label>
          <input
            id="editor-title-input"
            class="input editor-input"
            type="text"
            :value="article.title"
            @input="updateTitle"
          />
        </div>

        <div class="field">
          <label for="editor-abstract-area" class="label">Abstract:</label>
          <textarea
            id="editor-abstract-area"
            class="textarea editor-input"
            rows="6"
            :value="article.abstract"
            @input="updateAbstract"
          />
        </div>

        <div class="field">
          <label for="editor-content-area" class="label">Content: </label>
          <div class="grow-wrap">
            <textarea
              id="editor-content-area"
              ref="contentArea"
              class="editor-input"
              rows="10"
              :value="article.content"
              @input="updateContent"
            />
          </div>
        </div>
      </div>
    </div>

    <article-tags-modal ref="articleTagsModal" />
    <references-modal ref="referencesModal" :references="article.references" />
    <drafts-modal ref="draftsModal" />
    <images-modal
      ref="imagesModal"
      @insert-image-markdown="onInsertImageMarkdown"
    />
    <publish-modal ref="publishModal" />
    <collaborator-modal ref="collaboratorModal" />
  </div>
</template>

<script>
import EditToolbar from "./EditToolbar.vue";
import * as textFieldEdit from "text-field-edit";
import ArticleTagsModal from "./ArticleTagsModal.vue";
import ReferencesModal from "./ReferencesModal.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import DraftsModal from "./DraftsModal.vue";
import ImagesModal from "./ImagesModal.vue";
import PublishModal from "./PublishModal.vue";
import CollaboratorModal from "./CollaboratorModal.vue";

export default {
  components: {
    EditToolbar,
    ArticleTagsModal,
    ReferencesModal,
    DraftsModal,
    ImagesModal,
    PublishModal,
    CollaboratorModal,
  },
  name: "ArticleEditor",
  computed: {
    ...mapState("userInterfaceModule", ["prevFocusedElement"]),
    ...mapState("editArticleModule", ["article"]),
    ...mapState("userModule", ["user"]),
    isBlogger: function () {
      return this.user?.roles?.includes("blogger") ?? false;
    },
    titleInput: function () {
      return document.getElementById("editor-title-input");
    },
    abstractTextArea: function () {
      return document.getElementById("editor-abstract-area");
    },
    contentTextArea: function () {
      return document.getElementById("editor-content-area");
    },
  },

  methods: {
    ...mapMutations("editArticleModule", { updateArticle: "UPDATE" }),
    ...mapActions("editArticleModule", ["createNew", "saveDraft"]),
    processKeyDown: function (event) {
      if (!event.ctrlKey || event.metaKey) return true;

      const key = String.fromCharCode(event.keyCode).toLowerCase();
      switch (key) {
        case "s":
          this.saveArticle();
          event.preventDefault();
          return false;
        case "b":
          this.onBoldText();
          event.preventDefault();
          return false;
        case "i":
          this.onItalicText();
          event.preventDefault();
          return false;
        default:
          return true;
      }
    },
    saveArticle: async function () {
      try {
        if (!this.article.category) this.article.category = "ARTICLE";
        await this.saveDraft();
        this.$toast.success("Saved draft successfully");
      } catch (err) {
        this.$toast.error("Error saving draft");
      }
    },
    updateTitle: function (event) {
      this.updateArticle({ title: event.target.value });
    },
    updateAbstract: function (event) {
      this.updateArticle({ abstract: event.target.value });
    },
    updateContent: function (event) {
      const element = event.target;
      element.parentNode.dataset.replicatedValue = element.value;
      this.updateArticle({ content: element.value });
    },
    updateSentiment: function (event) {
      this.updateArticle({ reviewSentiment: event.target.value });
    },
    updateCategory: function (event) {
      this.updateArticle({ category: event.target.value });
    },
    elementCanAcceptText: function (element) {
      if (!element) return false;

      if (
        element.id !== this.titleInput.id &&
        element.id !== this.abstractTextArea.id &&
        element.id !== this.contentTextArea.id
      )
        return false;

      return true;
    },

    onCreateNewArticle: async function () {
      await this.createNew();
      await this.saveArticle();
    },

    onHeadingText: function () {
      const element = this.prevFocusedElement;
      if (!this.elementCanAcceptText(element)) return;

      textFieldEdit.wrapSelection(element, "\n\n# ", "\n\n");
      element.focus();
    },

    onBoldText: function () {
      const element = this.prevFocusedElement;
      if (!this.elementCanAcceptText(element)) return;

      textFieldEdit.wrapSelection(element, "**");
      element.focus();
    },

    onItalicText: function () {
      const element = this.prevFocusedElement;
      if (!this.elementCanAcceptText(element)) return;

      textFieldEdit.wrapSelection(element, "_");
      element.focus();
    },

    onInsertFormula: function () {
      const element = this.prevFocusedElement;
      if (!this.elementCanAcceptText(element)) return;

      textFieldEdit.wrapSelection(element, "\n\n$$\n", "\n$$\n\n");
      element.focus();
    },

    onInsertTable: function () {
      const element = this.prevFocusedElement;
      if (!this.elementCanAcceptText(element)) return;

      textFieldEdit.insert(
        element,
        "\n\n|            | **Align left**  | _**Align center**_ | _Align right_ |" +
          "\n|------------|:----------------|:------------------:|--------------:|" +
          "\n| **Row 1**  | 1               | 3                  | 5             |" +
          "\n| **Row 2**  | 2               | 4                  | 6             |\n\n"
      );
      element.focus();
    },

    onInsertHyperlink: function () {
      const element = this.prevFocusedElement;
      if (!this.elementCanAcceptText(element)) return;

      textFieldEdit.insert(
        element,
        "[Hyperlink text](https://www.example.com)"
      );
      element.focus();
    },

    onInsertImage: function () {
      this.$refs.imagesModal.openModal(this.prevFocusedElement);
    },

    onInsertImageMarkdown: async function (imageMarkdown, element) {
      if (!this.elementCanAcceptText(element)) {
        await navigator.clipboard?.writeText(imageMarkdown);
        this.$toast.success(
          "Saved image markdown to clipboard - paste in the editor to see your image"
        );
      }
      textFieldEdit.insert(element, imageMarkdown);
      element.focus();
    },

    onEditTags: function () {
      this.$refs.articleTagsModal.openModal();
    },

    onEditReferences: function () {
      this.$refs.referencesModal.openModal();
    },

    onAddCollaborator: function () {
      this.$refs.collaboratorModal.openModal();
    },

    openArticle: function () {
      this.$refs.draftsModal.openModal();
    },

    onPublish: function () {
      this.$refs.publishModal.openModal();
    },
  },
  mounted: function () {
    this.updateContent({ target: this.$refs.contentArea });
  },
};
</script>

<style scoped>
label {
  display: block;
}

.editor-input {
  resize: none;
  font-family: "Courier New", Courier, monospace;
}

.grow-wrap {
  display: grid;
}
.grow-wrap::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  padding: 0.5rem;
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;
}
</style>
