<template>
  <div class="container">
    <waiting-loader v-if="loading" />
    <template v-if="!loading">
      <div v-if="error" class="message is-danger">{{ error }}</div>
      <template v-if="invitation">
        <h1 class="title m-6">
          {{ senderName }} has invited you to edit an article with title:
          <div v-markdown="invitation.title" />
        </h1>

        <section class="section">
          <div v-if="expired" class="notification is-danger">
            This invitation has expired
          </div>
          <div v-if="alreadyAccepted" class="notification is-danger">
            This invitation has already been accepted
          </div>
          <div v-if="senderIsCurrentUser" class="notification is-warning">
            You can't accept your own invitation
          </div>
          <div v-if="!user.isLoggedIn" class="notification is-warning">
            <em>You must be logged in to accept</em>
          </div>
          <button
            class="
              button
              is-large is-success
              has-text-centered
              my-4
              is-fullwidth
            "
            :disabled="acceptButtonDisabled"
            @click="acceptInvitation"
          >
            Accept
          </button>
        </section>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";
import WaitingLoader from "../common/WaitingLoader.vue";
import { DateTime } from "luxon";

export default {
  components: {
    WaitingLoader,
  },
  name: "AcceptInvitationForm",
  props: ["invitationId"],
  data: function () {
    return {
      loading: false,
      error: null,
      invitation: null,
    };
  },
  computed: {
    ...mapState("userModule", ["user"]),
    acceptButtonDisabled: function () {
      return (
        !this.user.isLoggedIn ||
        this.expired ||
        this.alreadyAccepted ||
        this.senderIsCurrentUser
      );
    },
    expired: function () {
      const expiryDate = DateTime.fromISO(this.invitation.expiryDate);
      const diff = expiryDate.diffNow().toMillis();
      return diff < 0;
    },
    alreadyAccepted: function () {
      return !!this.invitation.recipient;
    },
    senderIsCurrentUser: function () {
      const senderId = this.invitation?.sender?.id;
      return !!senderId && this.user.id === senderId;
    },
    senderName: function () {
      return `${this.invitation?.sender?.firstName} ${this.invitation?.sender?.lastName}`;
    },
  },
  methods: {
    acceptInvitation: async function () {
      const response = await fetchFromApi(
        `/invitations/accept/${this.invitationId}`,
        { method: "POST" }
      );
      if (response.ok) {
        this.$toast.success(
          `You are now an author for article: ${this.invitation.title}`
        );
      } else {
        this.$toast.error("Error accepting invitation");
      }
    },
    fetchData: async function () {
      if (this.loading) return;
      this.loading = true;
      this.error = null;
      this.invitation = null;
      try {
        const response = await fetchFromApi(
          `/invitations/specific/${this.invitationId}`
        );
        if (response.ok) {
          this.invitation = await response.json();
        } else throw new Error("Error retrieving invitation from API");
      } catch (err) {
        console.error(err);
        this.error = "Error loading invitation";
      } finally {
        this.loading = false;
      }
    },
  },
  created: async function () {
    this.fetchData();
  },
};
</script>
