// taken from https://stackoverflow.com/questions/44820568/set-default-header-for-every-fetch-request
function updateOptions(options) {
  const update = { ...options };
  if (localStorage.jwt) {
    update.headers = {
      ...update.headers,
      Authorization: `Bearer ${localStorage.jwt}`,
    };
  }
  return update;
}

const serverUrl =
  process.env.NODE_ENV != "production"
    ? "http://localhost:3000/api"
    : "https://www.publishscience.org/api";

let logout;

const initLogout = (logoutFn) => {
  logout = logoutFn;
};

export { initLogout };

export default async function fetchFromApi(apiPath, options) {
  const response = await fetch(
    `${serverUrl}${apiPath}`,
    updateOptions(options)
  );

  if (response.status === 401 && logout) logout();

  return response;
}
