<template>
  <form class="forgot-password-form">
    <label class="forgot-password-label">
      Please enter your email address:
    </label>
    <input type="text" class="forgot-password-input" v-model="useremail" />
    <div class="forgot-password-reset-button">Send password reset email</div>
  </form>
</template>

<script>
export default {
  name: "ForgotPasswordForm",
  data: function () {
    return {
      useremail: null,
    };
  },
};
</script>

<style>
.forgot-password-form {
  width: 100%;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
}

.forgot-password-input {
  width: 100%;
  border: 1px solid black;
  margin: 10px;
}

.forgot-password-reset-button {
  border: 1px solid black;
  margin: 10px;
}
</style>
