<template>
  <div class="level is-mobile p-2">
    <div class="level-left">
      <button
        class="level-item button is-outlined m-1"
        :class="sentiment === 'POSITIVE' ? `is-danger` : null"
        :disabled="!user.isLoggedIn || isOwnArticle"
        @click="toggleUpvote"
        title="Upvote good science, even if you disagree with it"
      >
        <font-awesome-icon icon="heart" class="mr-2" />
        {{ upvotes || 0 }}
      </button>
      <button
        class="level-item button is-outlined m-1"
        :class="sentiment === 'NEGATIVE' ? `is-link` : null"
        :disabled="!user.isLoggedIn || isOwnArticle"
        @click="toggleDownvote"
        title="Downvote bad science, even if you agree with it"
      >
        <font-awesome-icon icon="heart-broken" class="mr-2" />
        {{ downvotes || 0 }}
      </button>
    </div>
    <div class="level-right">
      <button
        class="level-item button is-outlined m-1"
        :disabled="!user.isLoggedIn"
        :class="isFavourite ? `is-warning` : null"
        @click="toggleFavourite"
        title="Add to favourites"
      >
        <font-awesome-icon icon="star" class="mx-2 is-clickable" />
      </button>
      <button
        class="level-item button m-1 is-dark"
        @click="reviewArticle"
        title="Review this article"
      >
        <font-awesome-icon icon="gavel" class="mx-2 is-clickable" />
      </button>
      <button
        class="level-item button m-1 is-dark"
        @click="downloadPDF"
        title="Download PDF"
      >
        <font-awesome-icon icon="file-pdf" class="mx-2 is-clickable" />
      </button>
    </div>
  </div>
</template>

<script>
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";
import { mapActions, mapState } from "vuex";
import { PdfExporter } from "../../plumbing/pdf/pdfExport.js";
export default {
  name: "VotingWidget",
  props: ["article", "authors"],
  data: function () {
    return {
      loading: false,
      sentiment: null,
      upvotes: null,
      downvotes: null,
      isFavourite: null,
    };
  },
  computed: {
    ...mapState("userModule", ["user"]),
    isOwnArticle: function () {
      return this.authors && this.user
        ? this.authors.map((author) => author.id)?.includes(this.user.id)
        : false;
    },
  },
  methods: {
    ...mapActions("editArticleModule", ["createReview", "saveDraft"]),
    fetchUserVote: async function () {
      if (!this.user.isLoggedIn) return;
      const response = await fetchFromApi(`/vote/target/${this.article.id}`);
      if (response.ok) {
        const json = await response.json();
        this.sentiment = json?.sentiment;
      }
    },
    fetchArticleScore: async function () {
      const response = await fetchFromApi(
        `/vote/article/score/${this.article.id}`
      );
      if (response.ok) {
        const json = await response.json();
        this.upvotes = json.upvotes;
        this.downvotes = json.downvotes;
      }
    },
    fetchFavourite: async function () {
      if (!this.user.isLoggedIn) {
        this.isFavourite = false;
        return;
      }

      const response = await fetchFromApi(
        `/favourite/isMarked/${this.article.id}`
      );
      if (response.ok) {
        const json = await response.json();
        this.isFavourite = json.isFavourite;
      }
    },
    fetchData: async function () {
      if (this.loading) return;
      this.loading = true;
      this.sentiment = null;
      this.upvotes = null;
      this.downvotes = null;
      this.isFavourite = null;
      try {
        await Promise.all([
          this.fetchUserVote(),
          this.fetchArticleScore(),
          this.fetchFavourite(),
        ]);
      } finally {
        this.loading = false;
      }
    },
    toggleUpvote: async function () {
      const newSentiment =
        !this.sentiment || this.sentiment !== "POSITIVE"
          ? "POSITIVE"
          : "NEUTRAL";
      await fetchFromApi(`/vote/article/${this.article.id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ sentiment: newSentiment }),
      });
      this.fetchData();
    },
    toggleDownvote: async function () {
      const newSentiment =
        !this.sentiment || this.sentiment !== "NEGATIVE"
          ? "NEGATIVE"
          : "NEUTRAL";
      await fetchFromApi(`/vote/article/${this.article.id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ sentiment: newSentiment }),
      });
      this.fetchData();
    },
    toggleFavourite: async function () {
      try {
        await fetchFromApi(
          `/favourite/${this.isFavourite ? `un` : ``}mark/${this.article.id}`,
          { method: "POST" }
        );
        this.fetchData();
      } catch (err) {
        console.error(err);
        this.$toast.error(`Error toggling article favourite status`);
      }
    },
    downloadPDF: function () {
      PdfExporter.exportToPDF(this.article);
    },
    reviewArticle: async function () {
      await this.createReview({ article: this.article });
      await this.saveDraft();
      this.$router.push(`/write`);
    },
  },
  mounted: function () {
    this.fetchData();
  },
};
</script>
