<template>
  <div ref="grecaptcha" class="g-recaptcha"></div>
</template>

<script>
export default {
  name: "GoogleRecaptcha",
  props: ["sitekey"],
  methods: {
    grecaptchaLoaded: function () {
      window.grecaptcha.render(this.$refs.grecaptcha, {
        sitekey: this.sitekey,
        callback: (response) => this.$emit("captcha-complete", response),
      });
    },
  },
  mounted: function () {
    window.recaptchaLoaded = this.grecaptchaLoaded;
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src =
      "https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit";
    document.head.appendChild(script);
  },
};
</script>
