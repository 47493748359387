<template>
  <div class="loader-container">
    <div class="loader-spinner" />
  </div>
</template>

<script>
export default {
  name: "WaitingLoader",
  props: ["size"],
};
</script>

<style scoped>
.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.loader-spinner {
  display: inline-block;
  animation: spin 2s linear infinite;
  width: 100px;
  height: 100px;
  margin: auto;
  border: 16px solid gray;
  border-top: 16px solid black;
  border-radius: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
