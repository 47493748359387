<template>
  <div class="p-2">
    <div class="columns is-multiline is-1">
      <review-summary
        class="column is-4"
        v-for="(review, index) of reviewSelection"
        :key="index"
        :review="review"
      />
    </div>
    <div class="notification is-danger" v-if="!reviews || reviews.length === 0">
      <font-awesome-icon icon="gavel" />
      This article does not have any reviews yet - be the first to review it!
    </div>
  </div>
</template>

<script>
import ReviewSummary from "./ReviewSummary.vue";
export default {
  components: { ReviewSummary },
  name: "ReviewSummaryList",
  props: ["reviews"],
  computed: {
    reviewSelection: function () {
      return (this.reviews ?? []).slice(0, 3);
    },
  },
};
</script>
