<template>
  <span class="inline-icon-span">
    <div
      class="inline-icon"
      :class="disabled ? 'disabled-icon' : null"
      @click="!disabled ? navigate() : null"
      v-tooltip="toolTipText || ''"
    >
      <font-awesome-icon :icon="iconName" />
    </div>
  </span>
</template>

<script>
export default {
  name: "InlineIcon",
  props: ["iconName", "onClick", "disabled", "toolTipText"],
  methods: {
    navigate: function () {
      if (this.onClick) this.onClick();
    },
  },
};
</script>

<style scoped>
.inline-icon-span {
  display: inline-block;
}

.inline-icon {
  text-align: center;
  padding: 2px;
  margin: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
}

.inline-icon:hover {
  background-color: var(--site-hover-background-color);
}

.disabled-icon {
  cursor: default;
  color: var(--site-disabled-foreground-color);
}

.disabled-icon:hover {
  background-color: var(--site-background-color);
}
</style>
