<template>
  <div class="thumbnail-container">
    <div class="level is-mobile">
      <div class="level-left" />
      <div class="level-right">
        <button
          class="button is-danger level-item is-outlined"
          @click.prevent="deleteImage"
        >
          <font-awesome-icon icon="trash" />
        </button>
      </div>
    </div>
    <div @click="copyMarkdown" class="is-clickable">
      <div>{{ image.fileName }}</div>
      <img
        :src="imgsrc"
        :alt="image.fileName"
        loading="lazy"
        class="thumbnail-image"
      />
      <div v-markdown="image.attribution" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageThumbnail",
  props: ["image"],
  computed: {
    hostname: function () {
      if (process.env.NODE_ENV === "production")
        return "https://www.publishscience.org/api";
      else return "http://localhost:3000/api";
    },
    imgsrc: function () {
      return `${this.hostname}${this.image.path}`;
    },
  },
  methods: {
    copyMarkdown: async function () {
      const imageMarkdown = `\n\n\\image{${this.image.fileName}---**Your caption here**---${this.image.attribution}---${this.image.path}}\n\n`;
      this.$emit("thumbnail-clicked", imageMarkdown);
    },
    deleteImage: function () {
      this.$emit("delete-image", this.image);
    },
  },
};
</script>

<style scoped>
div {
  word-wrap: break-word;
}

.thumbnail-container {
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0.2rem;
  max-width: 10rem;
  text-align: center;
}
.thumbnail-image {
  max-width: 8rem;
  height: auto;
  margin: 0.5rem;
}
</style>
