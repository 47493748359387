const citationRule = /\\cite{(?<key>\w+)(:(?<meta>[\w -]*))?}/;

const citationsExtension = {
  name: "citation",
  level: "inline",
  references: [],
  start: function (src) {
    return src.match(citationRule)?.index;
  },
  tokenizer: function (src) {
    const match = src.match(citationRule);
    if (!match || match.index > 0) return false;

    return {
      type: "citation",
      raw: match[0],
      key: match.groups?.key,
      meta: match.groups?.meta,
    };
  },
  renderer: function (token) {
    const reference = citationsExtension.references.find(
      (ref) => ref.key === token.key
    );
    if (reference) {
      return `<a href="#psref-${token.key}">[${
        reference.shortDisplay + (token.meta ? ": " + token.meta : "")
      }]</a>`;
    }

    return `<span>(Citation: ${
      token.key + (token.meta ? ` Meta: ${token.meta}` : "")
    })</span>`;
  },
};

export default citationsExtension;
