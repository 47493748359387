import katex from "katex";

const inlineRule = /(?<bracket>`|(?:\$\$))(?<contents>.*?)\k<bracket>/;

const inlineLatexExtension = {
  name: "inlineLatex",
  level: "inline",
  start: function (src) {
    return src.match(inlineRule)?.index;
  },
  tokenizer: function (src) {
    if (this.lexer.disableInlineLatex) {
      return false;
    }

    const match = src.match(inlineRule);
    if (!match || match.index > 0) return false;

    const token = {
      type: "inlineLatex",
      raw: match[0],
      contents: match?.groups?.contents,
      isCode: match?.groups?.bracket?.includes("`"),
      tokens: [],
    };

    if (token.isCode) {
      this.lexer.disableInlineLatex = true;
      try {
        this.lexer.inlineTokens(token.raw, token.tokens);
      } finally {
        this.lexer.disableInlineLatex = false;
      }
    }

    return token;
  },
  renderer: function (token) {
    if (token.isCode) {
      return this.parser.parseInline(token.tokens);
    }

    return katex.renderToString(token.contents, {
      minRuleThickness: 0.06,
      throwOnError: false,
      displayMode: false,
    });
  },
};

export default inlineLatexExtension;
