<template>
  <generic-modal ref="modal" title="Settings">
    <div class="tabs is-centered is-large is-boxed is-toggle">
      <ul>
        <li
          class="mx-2"
          :class="currentTab === 'personalDetails' ? 'is-active' : null"
          @click="currentTab = 'personalDetails'"
        >
          Personal details
        </li>
        <li
          class="mx-2"
          :class="currentTab === 'contactDetails' ? 'is-active' : null"
          @click="currentTab = 'contactDetails'"
        >
          Contact details
        </li>
        <li
          class="mx-2"
          :class="currentTab === 'security' ? 'is-active' : null"
          @click="currentTab = 'security'"
        >
          Security
        </li>
      </ul>
    </div>

    <personal-details-form v-if="currentTab === 'personalDetails'" />
    <contact-details-form v-if="currentTab === 'contactDetails'" />
    <security-form v-if="currentTab === 'security'" @close-modal="closeModal" />
  </generic-modal>
</template>

<script>
import GenericModal from "../common/GenericModal.vue";
import ContactDetailsForm from "./ContactDetailsForm.vue";
import PersonalDetailsForm from "./PersonalDetailsForm.vue";
import SecurityForm from "./SecurityForm.vue";
export default {
  name: "UserSettingsModal",
  components: {
    GenericModal,
    SecurityForm,
    PersonalDetailsForm,
    ContactDetailsForm,
  },
  data: function () {
    return {
      currentTab: "personalDetails",
    };
  },
  methods: {
    openModal: function () {
      this.$refs.modal.openModal();
    },
    closeModal: function () {
      this.$refs.modal.closeModal();
    },
  },
};
</script>
