<template>
  <div>
    <form>
      <div class="field">
        <label class="label">First name:</label>
        <input class="input" type="text" v-model="firstName" />
      </div>
      <div class="field">
        <label class="label">Last name:</label>
        <input class="input" type="text" v-model="lastName" />
      </div>
      <button
        class="button m-2 p-1 is-outlined is-primary"
        type="submit"
        @click.prevent="updateName"
      >
        Update
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "PersonalDetailsForm",
  data: function () {
    return {
      firstName: null,
      lastName: null,
    };
  },
  methods: {
    ...mapActions("userModule", ["updateUserName"]),
    updateName: async function () {
      try {
        await this.updateUserName({
          firstName: this.firstName,
          lastName: this.lastName,
        });
        this.$toast.success("Successfully updated name");
      } catch (err) {
        this.$toast.error("Error updating name");
      }
    },
  },
  computed: {
    ...mapState("userModule", ["user"]),
  },
  mounted() {
    this.firstName = this.user?.firstName;
    this.lastName = this.user?.lastName;
  },
};
</script>
