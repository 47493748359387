import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFlask,
  faUser,
  faEdit,
  faPenSquare,
  faGlasses,
  faSave,
  faFolderOpen,
  faSquareRootAlt,
  faBold,
  faItalic,
  faUnderline,
  faImage,
  faLink,
  faThumbtack,
  faTable,
  faTags,
  faTag,
  faTimesCircle,
  faWindowClose,
  faCaretUp,
  faCaretDown,
  faAngleUp,
  faAngleDown,
  faComment,
  faFilePdf,
  faReply,
  faCheck,
  faThumbsUp,
  faThumbsDown,
  faBookmark,
  faStar,
  faHistory,
  faTrophy,
  faStarHalfAlt,
  faPoo,
  faEye,
  faGavel,
  faEnvelope,
  faColumns,
  faCogs,
  faSignOutAlt,
  faNewspaper,
  faPaw,
  faRss,
  faQuestionCircle,
  faTrash,
  faSearch,
  faHeart,
  faHeartBroken,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { faCreativeCommons } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const addIcons = () => {
  library.add({
    faFlask,
    faUser,
    faEdit,
    faPenSquare,
    faGlasses,
    faSave,
    faFolderOpen,
    faSquareRootAlt,
    faBold,
    faItalic,
    faUnderline,
    faImage,
    faLink,
    faThumbtack,
    faTable,
    faTags,
    faTag,
    faTimesCircle,
    faWindowClose,
    faCaretUp,
    faCaretDown,
    faAngleUp,
    faAngleDown,
    faComment,
    faFilePdf,
    faReply,
    faCheck,
    faThumbsUp,
    faThumbsDown,
    faBookmark,
    faStar,
    faHistory,
    faTrophy,
    faStarHalfAlt,
    faCreativeCommons,
    faPoo,
    faEye,
    faGavel,
    faEnvelope,
    faColumns,
    faCogs,
    faSignOutAlt,
    faNewspaper,
    faPaw,
    faRss,
    faQuestionCircle,
    faTrash,
    faSearch,
    faHeart,
    faHeartBroken,
    faPaperPlane,
  });

  Vue.component("font-awesome-icon", FontAwesomeIcon);
};

export { addIcons };
