<template>
  <div class="columns p-4 is-height-100-percent">
    <div class="column is-half m-1 p-2 expansion-space">
      <article-editor />
    </div>

    <div class="column is-half m-1 p-2 expansion-space">
      <article-view :article="article" :editorMode="true" />
    </div>
  </div>
</template>

<script>
import ArticleEditor from "./ArticleEditor.vue";
import ArticleView from "../articles/view/ArticleView.vue";
import { mapState } from "vuex";

export default {
  name: "EditArticle",
  components: {
    ArticleEditor,
    ArticleView,
  },
  computed: {
    ...mapState("editArticleModule", ["article"]),
  },
};
</script>
