<template>
  <div
    class="
      box
      is-rounded
      media
      has-background-light
      m-1
      p-1
      is-clipped is-whitespace-normal
    "
  >
    <div class="media-content p-2">
      <div class="is-fullwidth is-whitespace-normal">
        <span class="comment-body">
          <span class="content mr-2" v-markdown="comment.comment" />
        </span>
        <div class="is-inline-block mr-2">{{ dateDisplay }}</div>
        <div class="is-inline-block">
          <a :href="authorUrl">{{ authorName }}</a>
        </div>
      </div>

      <nav class="level is-mobile">
        <div class="level-left">
          <div
            class="level-item m-1"
            :disabled="!isLoggedIn || isOwnComment"
            @click="toggleUpvote"
          >
            <font-awesome-icon
              icon="heart"
              class="is-clickable mr-2"
              :class="sentiment === 'POSITIVE' ? `has-text-danger` : null"
            />
            {{ upvotes || 0 }}
          </div>
          <div
            class="level-item m-1"
            :disabled="!isLoggedIn || isOwnComment"
            @click="toggleDownvote"
          >
            <font-awesome-icon
              icon="heart-broken"
              class="is-clickable mr-2"
              :class="sentiment === 'NEGATIVE' ? `has-text-link` : null"
            />
            {{ downvotes || 0 }}
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "vuex";
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";

export default {
  name: "UserComment",
  props: ["comment"],
  data: function () {
    return {
      loading: false,
      sentiment: null,
      upvotes: null,
      downvotes: null,
    };
  },
  computed: {
    ...mapState("userModule", ["user"]),
    isLoggedIn: function () {
      return this.user?.isLoggedIn ?? false;
    },
    isOwnComment: function () {
      return this.user?.id === this.comment?.author?.id ?? false;
    },
    authorName: function () {
      return this.comment?.author?.name ?? "Unknown author";
    },
    authorUrl: function () {
      const id = this.comment?.author?.id;
      return `/user/${id}`;
    },
    dateDisplay: function () {
      return DateTime.fromISO(this.comment?.timestamp).toLocaleString(
        DateTime.DATETIME_MED
      );
    },
  },
  methods: {
    fetchUserVote: async function () {
      if (!this.user.isLoggedIn) return;
      const response = await fetchFromApi(`/vote/target/${this.comment?.id}`);
      if (response.ok) {
        const json = await response.json();
        this.sentiment = json?.sentiment;
      }
    },
    fetchCommentScore: async function () {
      const response = await fetchFromApi(
        `/vote/comment/score/${this.comment?.id}`
      );
      if (response.ok) {
        const json = await response.json();
        this.upvotes = json.upvotes;
        this.downvotes = json.downvotes;
      }
    },
    fetchData: async function () {
      if (this.loading) return;
      this.loading = true;
      this.sentiment = null;
      this.upvotes = null;
      this.downvotes = null;
      try {
        await Promise.all([this.fetchUserVote(), this.fetchCommentScore()]);
      } finally {
        this.loading = false;
      }
    },
    toggleUpvote: async function () {
      const newSentiment =
        !this.sentiment || this.sentiment !== "POSITIVE"
          ? "POSITIVE"
          : "NEUTRAL";
      await fetchFromApi(`/vote/comment/${this.comment.id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ sentiment: newSentiment }),
      });
      this.fetchData();
    },
    toggleDownvote: async function () {
      const newSentiment =
        !this.sentiment || this.sentiment !== "NEGATIVE"
          ? "NEGATIVE"
          : "NEUTRAL";
      await fetchFromApi(`/vote/comment/${this.comment.id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ sentiment: newSentiment }),
      });
      this.fetchData();
    },
    fireReplyEvent: function () {
      this.$emit("comment-reply");
    },
  },
  mounted: function () {
    this.fetchData();
  },
};
</script>
