<template>
  <form class="field" @submit.prevent="updateSearch">
    <p class="control has-icons-left">
      <input
        ref="searchInput"
        type="text"
        class="input is-primary"
        placeholder="Type your search terms here"
        :value="searchTerms"
      />
      <span class="icon is-left">
        <font-awesome-icon icon="search" />
      </span>
    </p>
    <button type="submit" style="display: none"></button>
  </form>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "SearchBar",
  computed: {
    ...mapState("searchModule", ["searchTerms"]),
  },
  methods: {
    ...mapMutations("searchModule", { updateSearchTerms: "UPDATE" }),
    updateSearch: function () {
      const searchTerms = this.$refs.searchInput.value;
      this.updateSearchTerms({ searchTerms });
    },
  },
};
</script>
