import { render, staticRenderFns } from "./ArticlesTab.vue?vue&type=template&id=96efe976&scoped=true&"
import script from "./ArticlesTab.vue?vue&type=script&lang=js&"
export * from "./ArticlesTab.vue?vue&type=script&lang=js&"
import style0 from "./ArticlesTab.vue?vue&type=style&index=0&id=96efe976&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96efe976",
  null
  
)

export default component.exports