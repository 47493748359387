const styleHelper = {
  renderer: {
    html: function () {
      return `<p style="color: red;">HTML markdown is disabled</p>`;
    },

    image: function () {
      return `<p style="color: red;">Image markdown is disabled</p>`;
    },

    table: function (header, body) {
      return `<div style="overflow-x: auto;">
        <table class="w3-table w3-border w3-bordered w3-hoverable" 
        style="width: auto; margin: 10px auto;">
          <thead>
            ${header}
          </thead>
          ${body}
        </table>
      </div>`;
    },

    tablecell: function (content, flags) {
      const style = `text-align: ${flags.align ?? "center"}; 
                      font-family: Arial, sans-serif;
                      border: 1px solid black;`;

      const tag = flags.header ? "th" : "td";

      return `<${tag} style="${style}">${content}</${tag}>`;
    },
  },
};

export default styleHelper;
