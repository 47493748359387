<template>
  <generic-modal :title="`Images`" ref="modal">
    <form ref="uploadForm" @submit.prevent="formSubmit" class="form">
      <div class="field">
        <label class="label"
          >Select a file for upload (max 100kB, PNG or JPEG images only)</label
        >
        <input
          class="input"
          name="upload"
          type="file"
          @change="handleFileChange"
          accept="image/jpeg, image/png"
        />
      </div>

      <div class="field">
        <label class="label"
          ><a
            href="https://wiki.creativecommons.org/wiki/Best_practices_for_attribution"
            target="_blank"
            >Attribution</a
          >
          for the selected image upload (use markdown):</label
        >
        <input
          class="input"
          type="text"
          name="attribution"
          v-model="attribution"
        />
      </div>

      <button
        class="button is-primary"
        :disabled="!attribution || !fileSelected"
      >
        Upload
      </button>
    </form>

    <div class="images-form expansion-space">
      <div class="images-form">
        Click a thumbnail to insert the relevant markdown in the editor
      </div>
      <waiting-loader v-if="loading" />
      <div class="w3-panel w3-red" v-if="error">{{ error }}</div>
      <div class="images-thumbnails images-padding">
        <image-thumbnail
          v-for="image of images"
          :key="image.id"
          :image="image"
          @thumbnail-clicked="thumbnailClicked"
          @delete-image="deleteImage"
        />
      </div>
    </div>
  </generic-modal>
</template>

<script>
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";
import GenericModal from "../common/GenericModal.vue";
import WaitingLoader from "../common/WaitingLoader.vue";
import ImageThumbnail from "../common/ImageThumbnail.vue";
import { mapState } from "vuex";

export default {
  components: { GenericModal, WaitingLoader, ImageThumbnail },
  name: "ImagesModal",
  data: function () {
    return {
      attribution: null,
      fileSelected: false,
      file: null,
      images: [],
      error: null,
      loading: false,
      prevFocusedElement: null,
    };
  },
  computed: {
    ...mapState("editArticleModule", ["article"]),
  },
  methods: {
    openModal: function (prevFocusedElement) {
      this.prevFocusedElement = prevFocusedElement;
      this.$refs.modal.openModal();
      this.fetchData();
    },
    closeModal: function () {
      this.$refs.modal.closeModal();
    },
    formSubmit: async function () {
      const body = new FormData();
      body.append("attribution", this.attribution);
      body.append("articleId", this.article?.id);
      body.append("upload", this.file);
      const response = await fetchFromApi("/images/", {
        method: "POST",
        body,
      });
      if (response.ok) {
        this.file = null;
        this.attribution = null;
        this.$refs.uploadForm.reset();
        this.$toast.success("Successfully uploaded image");
        this.fetchData();
      } else this.$toast.error("Error uploading image");
    },
    handleFileChange: function (event) {
      this.fileSelected = event.target.files?.length > 0 ?? false;
      this.file = this.fileSelected ? event.target.files[0] : null;
    },
    fetchData: async function () {
      if (this.loading) return;
      this.loading = true;
      this.error = null;
      try {
        const response = await fetchFromApi(
          `/images/userImages/${this.article?.id}`
        );
        if (response.ok) this.images = await response.json();
        else throw new Error("Error loading images");
      } catch (err) {
        console.error(err);
        this.error = err.message ?? "Error loading image gallery";
      } finally {
        this.loading = false;
      }
    },
    thumbnailClicked: function (imageMarkdown) {
      this.$emit(
        "insert-image-markdown",
        imageMarkdown,
        this.prevFocusedElement
      );
      this.closeModal();
    },
    deleteImage: async function (image) {
      if (!confirm(`Really delete ${image.fileName}?`)) return;
      try {
        await fetchFromApi(`/images/${image.id}`, { method: "DELETE" });
      } catch (err) {
        console.error(err);
        this.$toast.error(`Error deleting image ${image.fileName}`);
      }
      await this.fetchData();
    },
  },
};
</script>

<style scoped>
.images-form {
  margin: 0.5rem;
}

.images-display {
  display: block;
  margin: 0.5rem 0;
  width: 100%;
}

.images-padding {
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 5px;
}

.images-attribution {
  resize: none;
}

.images-thumbnails {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-flow: row wrap;
}
</style>
