<template>
  <div class="tag is-link m-1 is-clickable">
    <font-awesome-icon icon="tag" />
    &nbsp;{{ sanitisedTag }}&nbsp;
    <font-awesome-icon
      v-if="showDeleteButton"
      @click="emitDeleteEvent()"
      icon="times-circle"
    />
  </div>
</template>

<script>
export default {
  name: "ArticleTag",
  props: ["tag", "showDeleteButton"],
  methods: {
    emitDeleteEvent: function () {
      this.$emit("delete-tag-click");
    },
  },
  computed: {
    sanitisedTag: function () {
      return (this.tag || "").replace(/[^\w -]/g, "");
    },
  },
};
</script>

<style scoped>
.delete-tag-icon {
  cursor: pointer;
}
</style>
