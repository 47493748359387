import katex from "katex";

const blockRule =
  /((?:^|\n)(?<heading>#*)\$\$\n(?<contents>.*?)\n\$\$(?=(?:$|\s*|\n)))|(```(?<codeblock>.*?)```)/ms;

const blockLatexExtension = {
  name: "blockLatex",
  level: "block",
  start: function (src) {
    return src.match(blockRule)?.index;
  },
  tokenizer: function (src) {
    if (this.lexer.disableBlockLatex) {
      return false;
    }

    let match;
    if ((match = src.match(blockRule))) {
      if (match.index > 0) {
        return false;
      }

      const token = {
        type: "blockLatex",
        raw: match[0],
        heading: match?.groups?.heading,
        contents: match?.groups?.contents,
        isCode: !!match?.groups?.codeblock,
        tokens: [],
      };

      if (token.isCode) {
        this.lexer.disableBlockLatex = true;
        this.lexer.disableInlineLatex = true;
        try {
          this.lexer.blockTokens(token.raw, token.tokens);
        } finally {
          this.lexer.disableBlockLatex = false;
          this.lexer.disableInlineLatex = false;
        }
      }

      return token;
    }

    return false;
  },
  renderer: function (token) {
    if (token.isCode) {
      return this.parser.parse(token.tokens);
    }

    let headingSize = 0;
    if (token.heading) headingSize = Math.min(token.heading.length, 6);

    const renderedLatex = katex.renderToString(token.contents, {
      minRuleThickness: 0.06,
      throwOnError: false,
      displayMode: true,
    });

    return headingSize > 0
      ? `<h${headingSize}>${renderedLatex}</h${headingSize}>`
      : renderedLatex;
  },
};

export default blockLatexExtension;
