<template>
  <div class="article-sentiment-display">
    <h1 class="article-sentiment-icon" v-if="article.category === 'REVIEW'">
      <font-awesome-icon :icon="sentimentIcon" />
    </h1>
    <a :href="`/articles/${article.id}`" alt="Link to article"
      ><div class="article-title" v-markdown="article.title"
    /></a>
  </div>
</template>

<script>
export default {
  name: "ArticleTitleDisplay",
  props: ["article"],
  computed: {
    sentimentIcon: function () {
      switch (this.article?.reviewSentiment) {
        case "POSITIVE":
          return "trophy";
        case "NEGATIVE":
          return "poo";
        default:
          return "star-half-alt";
      }
    },
  },
};
</script>

<style scoped>
.article-sentiment-display {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.article-sentiment-icon {
  display: inline-block;
  margin: 0.5rem;
}

.article-title {
  flex: 1 0 auto;
}
</style>
