<template>
  <div class="content m-2 p-2" v-markdown="markdown"></div>
</template>

<script>
import { privacyPolicy, termsOfService } from "../../docs/policies.js";

export default {
  name: "BasicMarkdown",
  props: ["policyName"],
  computed: {
    markdown: function () {
      if (this.policyName === "privacyPolicy") return privacyPolicy;
      return termsOfService;
    },
  },
};
</script>
