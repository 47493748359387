import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";

const userModule = {
  namespaced: true,
  state: () => ({
    user: {
      id: null,
      firstName: "",
      lastName: "",
      reputation: 0,
      isLoggedIn: false,
      roles: [],
    },
  }),
  getters: {
    username: function (state) {
      return state.user.isLoggedIn
        ? `${state.user.firstName} ${state.user.lastName}`
        : null;
    },
  },
  mutations: {
    LOGIN(state, payload) {
      localStorage.jwt = payload.token;
      state.user = Object.assign({}, payload.person);
      state.user.isLoggedIn = true;
    },

    LOGOUT(state) {
      localStorage.clear();
      state.user = {
        id: null,
        firstName: "",
        lastName: "",
        reputation: 0,
        isLoggedIn: false,
        roles: [],
      };
    },

    UPDATENAME(state, payload) {
      state.user.firstName = payload.firstName;
      state.user.lastName = payload.lastName;
    },
  },
  actions: {
    async login(context, payload) {
      const response = await fetchFromApi("/users/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        const responsePayload = await response.json();
        context.commit("LOGIN", responsePayload);
      } else throw new Error("Could not login");
    },
    async logout({ commit }) {
      commit("LOGOUT");
    },
    async updateUserName({ commit }, payload) {
      const response = await fetchFromApi("/users/updateName", {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        commit("UPDATENAME", payload);
      } else throw new Error("Could not update name");
    },
    async updatePassword({ commit }, payload) {
      const response = await fetchFromApi("/users/updatePassword", {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        commit("LOGOUT");
      } else throw new Error("Could not update password");
    },
  },
};

export { userModule };
