<template>
  <div>
    <error-display v-if="error" :error="error" />
    <waiting-loader v-if="loading" />
    <div v-if="!loading">
      <h1 v-if="successMessage">
        {{ successMessage }}
      </h1>
      <form v-if="!successMessage">
        <button
          class="w3-button w3-border w3-border-black w3-margin w3-padding"
          @submit.prevent="resendVerificationEmail"
        >
          Resend Verification Email
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import fetchFromApi from "../../plumbing/fetchwrapper/fetchWrapper.js";
import WaitingLoader from "../common/WaitingLoader.vue";
import ErrorDisplay from "../common/ErrorDisplay.vue";

export default {
  name: "VerificationForm",
  components: {
    WaitingLoader,
    ErrorDisplay,
  },
  data: function () {
    return {
      successMessage: null,
      loading: false,
      error: null,
    };
  },
  methods: {
    fetchData: async function () {
      if (this.loading) return;
      this.loading = true;
      this.successMessage = null;
      try {
        const response = await fetchFromApi(
          `/users/verify/${this.$route.params.verificationCode}`,
          {
            method: "POST",
          }
        );
        this.successMessage = response.ok
          ? "You have registered successfully - click the Login button at the top right to login"
          : null;
        if (!response.ok) throw new Error("Email verification failed.");
      } catch (err) {
        this.error = err;
        this.successMessage = null;
      } finally {
        this.loading = false;
      }
    },
    resendVerificationEmail: async function () {
      if (this.loading) return;
      this.loading = true;
      this.successMessage = null;
      try {
        const response = await fetchFromApi(
          `/users/resendVerificationEmail/${this.$route.params.verificationCode}`,
          {
            method: "POST",
          }
        );
        this.successMessage = response.ok
          ? "Email sent - please check your email and click on the verification link"
          : null;
        if (!response.ok) throw new Error("Failed to send verification email.");
      } catch (err) {
        this.error = err;
        this.successMessage = null;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted: function () {
    this.fetchData();
  },
};
</script>

<style scoped></style>
