<template>
  <div class="is-height-100-percent">
    <div
      v-if="!user || !user.isLoggedIn"
      class="notification is-warning content"
    >
      You cannot write articles unless you are logged in. You can sign up and
      publish articles for free :) We are a fancy-pants website and we even have
      Terms of Service and a Privacy Policy (very inexpertly written). See the
      links below for details :)
    </div>
    <div v-if="!!user && user.isLoggedIn" class="is-height-100-percent">
      <edit-article v-if="article.category !== 'REVIEW'" />
      <edit-review v-if="article.category === 'REVIEW'" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EditArticle from "./EditArticle.vue";
import EditReview from "./EditReview.vue";

export default {
  name: "WriteArticle",
  components: {
    EditArticle,
    EditReview,
  },
  computed: {
    ...mapState("userModule", ["user"]),
    ...mapState("editArticleModule", ["article"]),
  },
};
</script>
