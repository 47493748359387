<template>
  <form class="login-form" @submit.prevent="attemptLogin">
    <div class="field">
      <label class="label">Email address</label>
      <input type="email" class="input" v-model="useremail" />
    </div>
    <div class="field">
      <label class="label">Password</label>
      <input type="password" class="input" v-model="userpassword" />
    </div>
    <p class="help">Click <a href="#">here</a> if you forgot your password</p>
    <div class="help is-danger" v-if="error">
      {{ error }}
    </div>
    <button class="button is-primary m-2 p-2">Login</button>
  </form>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "LoginForm",
  data: function () {
    return {
      useremail: null,
      userpassword: null,
      attemptingLogin: false,
      error: null,
    };
  },
  methods: {
    ...mapActions("userModule", ["login"]),
    sendSuccessEvent: function () {
      this.$toast.success("Logged in successfully");
      this.$emit("login-success");
    },
    attemptLogin: async function (event) {
      event.preventDefault();
      this.attemptingLogin = true;
      this.error = null;
      try {
        await this.login({
          email: this.useremail,
          password: this.userpassword,
        });
        this.sendSuccessEvent();
      } catch (err) {
        console.error(err);
        this.error = err.message;
        this.$toast.error("Error logging in");
      } finally {
        this.attemptingLogin = false;
      }
    },
  },
};
</script>
