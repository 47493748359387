<template>
  <div :class="loading ? 'expansion-space' : null">
    <waiting-loader v-if="loading" />
    <div v-else>
      <error-display v-if="error" :error="error" />
      <review-summary-list
        v-if="!!article"
        :reviews="article.reviewSummaries"
        class="container"
      />
      <div class="columns">
        <div :class="!!reviewedArticle ? `column is-6` : `column is-12`">
          <article-view
            v-if="!!article"
            :article="article"
            :editorMode="false"
          />
        </div>
        <div v-if="!!reviewedArticle" class="column is-6">
          <article-view :article="reviewedArticle" :editorMode="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleView from "./ArticleView.vue";
import ReviewSummaryList from "../../reviews/ReviewSummaryList.vue";
import WaitingLoader from "../../common/WaitingLoader.vue";
import ErrorDisplay from "../../common/ErrorDisplay.vue";
import fetchFromApi from "../../../plumbing/fetchwrapper/fetchWrapper.js";

export default {
  name: "ArticleFullDisplay",
  metaInfo: function () {
    return {
      title: `PublishScience.org - Article: ${this.article?.title}`,
      meta: [
        {
          name: "description",
          content: this.article?.abstract,
        },
        {
          property: "og:title",
          content: `PublishScience.org - Article: ${this.article?.title}`,
        },
        {
          property: "og:description",
          content: this.article?.abstract,
        },
        {
          property: "og:url",
          content: `www.publishscience.org/articles/${this.article?.id}`,
        },
        { property: "og:type", content: "article" },
      ],
    };
  },
  components: {
    ArticleView,
    ReviewSummaryList,
    WaitingLoader,
    ErrorDisplay,
  },
  props: ["articleId"],
  data: function () {
    return {
      article: null,
      reviewedArticle: null,
      error: null,
      loading: false,
    };
  },
  methods: {
    fetchData: async function () {
      if (this.loading) return;
      this.loading = true;
      this.error = null;
      try {
        let response = await fetchFromApi(`/articles/${this.articleId}`);
        if (response.ok) this.article = await response.json();
        else
          throw new Error(
            `Unexpected response from server. Status: ${response.status}`
          );

        if (this.article?.parentId && this.article?.category === "REVIEW") {
          response = await fetchFromApi(`/articles/${this.article.parentId}`);
          if (response.ok) this.reviewedArticle = await response.json();
          else
            throw new Error(
              `Unexpected response from server. Status: ${response.status}`
            );
        } else {
          this.reviewedArticle = null;
        }
      } catch (err) {
        console.error(err);
        this.error = err.message;
        this.article = null;
        this.reviewedArticle = null;
      } finally {
        this.loading = false;
      }
    },
  },
  created: function () {
    this.fetchData();
  },
  watch: {
    articleId: "fetchData",
  },
};
</script>
